::-webkit-scrollbar {
    width: 5px;
}
  
/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
   
/* Handle */
::-webkit-scrollbar-thumb {
    background: gray; 
    border-radius: 10px;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgb(177, 177, 177); 
}