.z-100 {
    z-index: 100;
}
/* Target ul and li inside the specific container */
.ngx-editor-content ul {
    list-style-type: disc; /* Enable bullets */
    margin-left: 1rem; /* Add indentation */
    padding-left: 0; /* Adjust spacing */
  }
  
  .ngx-editor-content li {
    margin-bottom: 0.5rem; /* Add spacing between list items */
  }

  .ngx-editor-content ol {
    list-style-type: decimal; /* Show numbers */
    margin-left: 1rem; /* Indent numbers */
    padding-left: 0;
  }
  