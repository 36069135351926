import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root',
})
export class SweetAlertService {
  private customSwal;

  constructor() {
    const rootStyles = getComputedStyle(document.documentElement);
    this.customSwal = Swal.mixin({
      confirmButtonColor: rootStyles.getPropertyValue('--brand-700').trim(), // Blue
      cancelButtonColor: '#dc3545', // Red
      buttonsStyling: true,
    });
  }

  showAlert(title: string, text: string, icon: 'success' | 'error' | 'warning' | 'info') {
    return this.customSwal.fire({
      title,
      text,
      icon,
      confirmButtonText: 'OK',
    });
  }
}
