import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  ViewEncapsulation,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { FilterItemComponent } from '../filter-item/filter-item.component';

@Component({
    selector: 'app-more-filters',
    templateUrl: './more-filters.component.html',
    styleUrl: './more-filters.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [FilterItemComponent]
})
export class MoreFiltersComponent implements OnInit, OnChanges {
  @Input() brandsFilters = [];
  @Input() groupsFilters = [];
  @Input() selectedGroupsFilters = [];
  @Input() selectedBrandsFilters = [];
  @Output() isMoreFiltersOpen = new EventEmitter<boolean>();
  @Output() applyMoreFilter = new EventEmitter<any>();

  selectedFilter = '';

  ngOnInit() {}

  closeMoreFilters() {
    this.isMoreFiltersOpen.emit(false);
  }

  setSelectedFilter(event) {
    this.selectedFilter = event;
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes['groupsFilters']) {
      this.groupsFilters = changes['groupsFilters']?.currentValue;
    }
    if (changes['selectedBrandsFilters']) {
      this.selectedBrandsFilters = changes['selectedBrandsFilters']?.currentValue;
      this.selectedBrandsFilters = this.selectedBrandsFilters?.map((g) => g.value);
      this.brandsFilters?.forEach((b) => {
        b.selected = false;
        if (this.selectedBrandsFilters.includes(b.value)) {
          b.selected = true;
        }
      });
    }
    if (changes['selectedGroupsFilters']) {
      this.selectedGroupsFilters = changes['selectedGroupsFilters']?.currentValue;
      this.selectedGroupsFilters = this.selectedGroupsFilters.map((g) => g.value);
      this.groupsFilters.forEach((b) => {
        b.selected = false;
        if (this.selectedGroupsFilters.includes(b.value)) {
          b.selected = true;
        }
      });
    }
  }
  applyMoreFilterFn() {
    const params = {
      selectedBrands: this.brandsFilters?.filter((b) => b.selected),
      selectedgroups: this.groupsFilters?.filter((b) => b.selected),
    };
    this.applyMoreFilter.emit(params);
  }
}
