import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EddressFacadeService } from '@app/core/services/eddressFacade.service';
import { AuthService } from '@app/core/services/auth.service';
import { NotificationService } from '@app/core/services/notification.service';
import { LoaderService } from '@app/core/services/loader.service';
import { FirebaseAuthErrorHandler } from '@app/core/error/firebaseError';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrl: './forgot-password.component.scss',
    imports: [NgIf, FormsModule, ReactiveFormsModule]
})
export class ForgotPasswordComponent implements OnInit {

  @Output() isForgotPasswordOpen = new EventEmitter<boolean>();
  @Output() isLoginOpen = new EventEmitter<boolean>();
  formGroup: FormGroup;

  disableLoginBtn = false;
  isSubmitted = false;

  resetEmail;
  isSent = false;

  constructor(
    private formBuilder: FormBuilder,
    public eddress: EddressFacadeService,
    public authService: AuthService,
    private notification: NotificationService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this._initForm();
  }

  closeForgotPassword() {
    this.isForgotPasswordOpen.emit(false);
  }

  openLogin() {
    this.isLoginOpen.emit(true);
  }

  private _initForm() {
    this.formGroup = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    const val = this.formGroup.value;

    if (val.email) {
      this.resetEmail = val.email;

      this.loaderService.open();
      this.authService
        .forgotPassword(this.resetEmail)
        .then(() => {
          this.loaderService.close();
          this.isSent = true;
        })
        .catch((error) => {
          const errorMessage = FirebaseAuthErrorHandler.convertMessage(error['code']);
          this.notification.errorNotification(errorMessage);
          this.loaderService.close();
        });
    }
  }

  get form() {
    return this.formGroup.controls;
  }

}
