<div
  *ngIf="product"
  class="self-stretch flex flex-row items-center justify-start py-6 px-4 md:px-16 gap-[12px] text-gray-600 bg-gray-50"
>
  <div class="hidden flex-row items-start justify-start">
    <img class="w-5 relative h-5 overflow-hidden shrink-0" alt="hidden icon" />
  </div>
  <img class="w-4 relative h-4 overflow-hidden shrink-0 hidden" alt="hidden icon" />

  <div class="flex flex-row items-center justify-center">
    <a class="relative leading-[20px]" href="/">Home</a>
  </div>
  <img
    class="w-4 relative h-4 overflow-hidden shrink-0"
    alt="right icon"
    src="assets/images/product/chevronright.svg"
  />

  <div
    class="flex flex-row items-center justify-center cursor-pointer"
    (click)="viewCollection(product?.productCollections[0], null)"
  >
    <div class="relative leading-[20px]">{{ product?.productCollections[0]?.name }}</div>
  </div>
  <img
    class="w-4 relative h-4 overflow-hidden shrink-0"
    alt="right icon"
    src="assets/images/product/chevronright.svg"
  />

  <ng-container *ngIf="product?.productCollections[0]?.group">
    <div
      (click)="
        viewCollection(product?.productCollections[0], product?.productCollections[0]?.group)
      "
      class="flex flex-row items-center justify-center cursor-pointer"
      *ngIf="product?.productCollections"
    >
      <div class="relative leading-[20px]">{{ product?.productCollections[0]?.group }}</div>
    </div>
    <img
      class="w-4 relative h-4 overflow-hidden shrink-0"
      alt="right icon"
      src="assets/images/product/chevronright.svg"
    />
  </ng-container>
  <div class="flex flex-row items-center justify-center text-brand-700">
    <div class="relative leading-[20px] font-semibold">{{ product?.label }}</div>
  </div>
</div>
<div
  class="self-stretch flex flex-col md:flex-row items-start justify-start pt-0 px-4 md:px-16 pb-8 gap-[32px] bg-gray-50"
>
  <div class="self-stretch flex flex-row items-start justify-start gap-[12px]">
    <div class="flex flex-col items-start justify-start gap-[12px]">
      <div
        class="flex flex-row items-start justify-start bg-base-white box-border border-[1px] border-solid border-gray-2001 rounded-lg"
        *ngFor="let image of images; let i = index"
        [ngClass]="{ 'opacity-[0.6]': i !== selectedImage.index }"
      >
        <img
          class="w-[94px] relative rounded-lg md:h-[94px]"
          alt="product image"
          [src]="image.imageUrl ? image.imageUrl : 'assets/images/home/no-image-icon.png'"
          (click)="selectImage(image, i)"
        />
      </div>
    </div>
    <div
      *ngIf="selectedImage?.imageUrl"
      class="md:h-[602px] relative max-w-[550px] flex flex-row items-start justify-start bg-base-white box-border border-[1px] border-solid border-gray-2001 rounded-lg"
    >
      <img
        class="self-stretch relative rounded-lg max-h-full"
        alt="product image"
        [src]="
          selectedImage.imageUrl ? selectedImage.imageUrl : 'assets/images/home/no-image-icon.png'
        "
      />
      <!-- <lib-ngx-image-zoom
        [thumbImage]="selectedImage"
        [fullImage]="selectedImage"
        [magnification]="1"
        [enableScrollZoom]="true"
        [enableLens]="true"
        [lensWidth]="200">
      </lib-ngx-image-zoom> -->
      <div
        *ngIf="product?.outOfStock"
        class="my-0 mx-[!important] absolute top-[10px] left-[10px] rounded-8xs bg-error-50 flex flex-row items-center justify-start py-0.5 pr-1.5 pl-2 gap-[4px] z-[1] border-[1px] border-solid border-error-200"
      >
        <div class="relative leading-[18px] font-semibold text-error-700 text-xs">Out of stock</div>
        <img
          class="w-3 relative h-3 overflow-hidden shrink-0"
          alt="bell ring icon"
          src="assets/images/home/bellringing02.svg"
        />
      </div>
    </div>
  </div>
  <div class="self-stretch flex-1 flex flex-col items-start justify-start gap-[16px]">
    <div
      class="rounded-2xl bg-error-50 flex flex-row items-center justify-start py-0.5 px-2.5 text-center text-error-700 border-[1px] border-solid border-error-200"
      *ngIf="product?.discountInPercent > 0"
    >
      <b class="relative leading-[20px]">{{ product?.discountInPercent }}% OFF</b>
    </div>
    <div
      *ngIf="product?.label"
      class="self-stretch flex flex-col items-start justify-start gap-[12px] text-5xl text-base-black"
    >
      <h1 class="self-stretch relative leading-[32px] font-bold">
        {{ product?.label }}
      </h1>
      <div class="self-stretch relative text-sm leading-[20px] font-semibold text-gray-500">
        Pack size : {{ product?.size }}
      </div>
      <div class="self-stretch flex flex-row items-start justify-start text-11xl text-brand-500">
        <div class="flex-1 flex flex-col items-start justify-start">
          <b class="relative leading-[38px]"
            >{{ configuration?.tenantCurrencySymbol }}{{ product?.price | number : "1.2-2" }}</b
          >
          <div
            *ngIf="product?.actualPrice && product?.discountInPercent"
            class="relative text-sm [text-decoration:line-through] leading-[20px] font-semibold text-gray-400"
          >
            {{ configuration?.tenantCurrencySymbol }}{{ product?.actualPrice | number : "1.2-2" }}
          </div>
          <div class="self-stretch relative text-xs leading-[18px] font-semibold text-gray-500">
            VAT Included
          </div>
        </div>
        <div
          class="flex flex-row items-center justify-start gap-[4px] text-xs text-gray-500"
          *ngIf="product?.ratings"
        >
          <div class="relative leading-[18px] font-semibold">Reviews (24)</div>
          <div class="flex items-center">
            <svg
              class="w-4 h-4 text-yellow-300 ms-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path
                d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"
              />
            </svg>
            <svg
              class="w-4 h-4 text-yellow-300 ms-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path
                d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"
              />
            </svg>
            <svg
              class="w-4 h-4 text-yellow-300 ms-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path
                d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"
              />
            </svg>
            <svg
              class="w-4 h-4 text-yellow-300 ms-1"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path
                d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"
              />
            </svg>
            <svg
              class="w-4 h-4 ms-1 text-gray-300 dark:text-gray-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 22 20"
            >
              <path
                d="M20.924 7.625a1.523 1.523 0 0 0-1.238-1.044l-5.051-.734-2.259-4.577a1.534 1.534 0 0 0-2.752 0L7.365 5.847l-5.051.734A1.535 1.535 0 0 0 1.463 9.2l3.656 3.563-.863 5.031a1.532 1.532 0 0 0 2.226 1.616L11 17.033l4.518 2.375a1.534 1.534 0 0 0 2.226-1.617l-.863-5.03L20.537 9.2a1.523 1.523 0 0 0 .387-1.575Z"
              />
            </svg>
          </div>
          <!-- <img
            class="w-4 relative h-4 overflow-hidden shrink-0"
            alt="s"
            src="assets/images/product/star-icon.svg"
          />

          <img
            class="w-4 relative h-4 overflow-hidden shrink-0"
            alt="s"
            src="assets/images/product/star-icon.svg"
          />

          <img
            class="w-4 relative h-4 overflow-hidden shrink-0"
            alt="s"
            src="assets/images/product/star-icon.svg"
          />

          <img
            class="w-4 relative h-4 overflow-hidden shrink-0"
            alt="s"
            src="assets/images/product/star-icon.svg"
          />

          <img
            class="w-4 relative h-4 overflow-hidden shrink-0"
            alt="s"
            src="assets/images/product/star-icon.svg"
          /> -->
        </div>
      </div>
    </div>
    <div
      *ngIf="product?.label"
      class="self-stretch flex flex-row md:items-center md:justify-end text-gray-true-700"
    >
      <div
        class="self-stretch md:flex-1 flex flex-col md:flex-row gap-[12px] items-center justify-between"
      >
        <div class="self-stretch flex flex-row items-center justify-start gap-[12px]">
          <div class="self-stretch rounded-lg flex flex-row items-start justify-between gap-[12px]">
            <div
              class="w-24 flex-1 rounded-lg bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] box-border overflow-hidden flex flex-row items-center justify-between py-3.5 px-3.5 border-[1px] border-solid border-gray-true-300"
            >
              <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

              <img
                class="w-4 relative h-4 overflow-hidden shrink-0 cursor-pointer"
                alt="minus icon"
                src="assets/images/product/minus.svg"
                (click)="removeQuantity()"
              />

              <div class="flex flex-row items-center justify-center py-0 px-0.5">
                <div class="relative leading-[20px] font-semibold">{{ quantity }}</div>
              </div>
              <img
                class="w-4 relative h-4 overflow-hidden shrink-0 cursor-pointer"
                alt="add product icon"
                src="assets/images/product/plus.svg"
                (click)="addQuantity()"
              />
            </div>
            <button
              type="button"
              class="rounded-lg bg-brand-600 disabled:opacity-75 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-3 px-[18px] gap-[6px] text-base text-base-white border-[1px] border-solid border-brand-600"
              (click)="addToCart()"
              [disabled]="quantity === 0 || product?.outOfStock"
              [class.cursor-pointer]="quantity > 0"
            >
              <img
                class="w-5 relative h-5 overflow-hidden shrink-0"
                alt="shoping cart icon"
                src="assets/images/product/shoppingcart03.svg"
              />

              <div class="flex flex-row items-center justify-center py-0 px-0.5">
                <div class="relative leading-[24px] font-semibold">Add to Cart</div>
              </div>
              <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
            </button>
          </div>
        </div>
        <div
          class="w-[355px] flex flex-row md:items-center md:justify-end gap-[12px] text-base text-brand-700"
        >
          <div
            class="rounded-lg shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-3 px-[18px] gap-[6px] border-[1px] border-solid border-brand-300 cursor-pointer"
            (click)="addToFavourite()"
          >
            <img
              class="w-5 relative h-5 overflow-hidden shrink-0"
              alt="heart icon"
              [src]="
                isFavorite
                  ? 'assets/images/home/heart-rounded-fill.svg'
                  : 'assets/images/product/heart.svg'
              "
            />

            <div class="flex flex-row items-center justify-center py-0 px-0.5" *ngIf="!isFavorite">
              <div class="relative leading-[24px] font-semibold">Add to Wishlist</div>
            </div>
            <div class="flex flex-row items-center justify-center py-0 px-0.5" *ngIf="isFavorite">
              <div class="relative leading-[24px] font-semibold remove-text">
                Remove from Wishlist
              </div>
            </div>
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
          </div>
          <div class="relative">
            <div
              class="rounded-lg shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-3 px-[18px] gap-[6px] border-[1px] border-solid border-brand-300 cursor-pointer"
              (click)="toggleShareDropdown()"
            >
              <img
                class="w-5 relative h-5 overflow-hidden shrink-0"
                alt="share icon"
                src="assets/images/product/share02.svg"
              />

              <div class="flex flex-row items-center justify-center py-0 px-0.5">
                <div class="relative leading-[24px] font-semibold">Share...</div>
              </div>
              <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
            </div>
            <div
              class="absolute z-10 top-full right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
              *ngIf="isShareDropdownOpen"
              id="shareDropdown"
              style="right: 0"
            >
              <div class="py-1">
                <a
                  target="_blank"
                  href="https://www.facebook.com/sharer/sharer.php?u={{
                    currentUrl
                  }}&amp;src=sdkpreparse"
                  class="block flex items-center justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  (click)="selectShareOption('facebook')"
                >
                  <img
                    src="assets/images/product/facebook-icon.svg"
                    class="relative h-5 overflow-hidden shrink-0 mr-2"
                    alt="Facebook"
                  />
                  <span>Facebook</span>
                </a>
                <a
                  href="http://twitter.com/share?text=Check this product&url={{ currentUrl }}"
                  target="_blank"
                  class="block flex items-center justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  (click)="selectShareOption('x')"
                >
                  <img
                    src="assets/images/product/x-icon.svg"
                    class="relative h-5 overflow-hidden shrink-0 mr-2"
                    alt="X"
                  />
                  <span>X</span>
                </a>
                <a
                  href="https://web.whatsapp.com/send?text={{ currentUrl }}"
                  target="_blank"
                  class="block flex items-center justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  (click)="selectShareOption('whatsapp')"
                >
                  <img
                    src="assets/images/product/whatsapp-icon.svg"
                    class="relative h-5 overflow-hidden shrink-0 mr-2"
                    alt="Whatsapp"
                  />
                  <span>Whatsapp</span>
                </a>
                <a
                  href="mailTo:?subject=Check this product&body=I tried this product, you must check it out {{
                    currentUrl
                  }}"
                  class="block flex items-center justify-start px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  (click)="selectShareOption('email')"
                >
                  <img
                    src="assets/images/product/email-icon.svg"
                    class="relative h-5 overflow-hidden shrink-0 mr-2"
                    alt="Email"
                  />
                  <span>Email</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="self-stretch flex flex-col items-start justify-start gap-[8px]"
      *ngIf="product?.description && product?.description !== ''"
    >
      <b class="relative leading-[20px]">HIGHLIGHTS</b>
      <div class="self-stretch relative text-base leading-[24px] font-semibold text-gray-500">
        {{ product.description }}
      </div>
    </div>
    <div
      class="self-stretch flex flex-row items-start justify-center"
      *ngIf="product?.additionalInformation && product?.additionalInformation !== ''"
    >
      <div class="flex-1 flex flex-col items-start justify-start">
        <img
          class="self-stretch relative max-w-full overflow-hidden h-px shrink-0"
          alt="divider image"
          src="assets/images/product/divider.svg"
        />
      </div>
    </div>
    <div
      class="self-stretch flex flex-row items-center justify-between"
      *ngIf="product?.additionalInformation && product?.additionalInformation !== ''"
    >
      <b class="relative leading-[20px]">DESCRIPTION</b>
      <img
        class="w-6 relative h-6 overflow-hidden shrink-0 cursor-pointer"
        alt="down icon"
        src="assets/images/product/chevrondown.svg"
        (click)="showDescription()"
      />
    </div>
    <div
      class="self-stretch relative text-base leading-[24px] font-semibold text-gray-500"
      *ngIf="isDescriptionOpen"
    >
      {{ product.additionalInformation }}
    </div>
    <div
      class="self-stretch flex flex-row items-start justify-center"
      *ngIf="product?.description && product?.description !== ''"
    >
      <div class="flex-1 flex flex-col items-start justify-start">
        <img
          class="self-stretch relative max-w-full overflow-hidden h-px shrink-0"
          alt="divider image"
          src="assets/images/product/divider.svg"
        />
      </div>
    </div>
    <div
      class="self-stretch flex flex-row items-center justify-between"
      *ngIf="product?.additives || product?.allergens"
    >
      <b class="relative leading-[20px]">ADDITIVES & ALLERGENS</b>
      <img
        class="w-6 relative h-6 overflow-hidden shrink-0 cursor-pointer"
        alt="down icon"
        src="assets/images/product/chevrondown.svg"
        (click)="showAdditives()"
      />
    </div>
    <div
      class="self-stretch relative text-base leading-[24px] font-semibold text-gray-500"
      *ngIf="isAdditivesOpen"
    >
      <div
        class="grid grid-cols-4 flex flex-row items-center justify-start gap-[10px_12px] text-center text-brand-700 mb-5"
      >
        <div
          class="rounded-2xl bg-brand-50 flex flex-row items-center justify-start py-1 pr-1 pb-2 pl-3 pr-3 gap-[0px_2px] border-[1px] border-solid border-brand-200"
          *ngFor="let additive of product?.additives"
        >
          <div class="relative leading-[20px] text-center w-full">{{ additive.label }}</div>
        </div>
        <div
          class="rounded-2xl bg-brand-50 flex flex-row items-center justify-start py-1 pr-1 pb-2 pl-3 pr-3 gap-[0px_2px] border-[1px] border-solid border-brand-200"
          *ngFor="let allergen of product?.allergens"
        >
          <div class="relative leading-[20px] text-center w-full">{{ allergen.label }}</div>
        </div>
      </div>
    </div>
    <div
      class="self-stretch flex flex-row items-start justify-center"
      *ngIf="product?.additives || product?.allergens"
    >
      <div class="flex-1 flex flex-col items-start justify-start">
        <img
          class="self-stretch relative max-w-full overflow-hidden h-px shrink-0"
          alt="divider image"
          src="assets/images/product/divider.svg"
        />
      </div>
    </div>
  </div>
</div>
<app-landing-best-sellers
  *ngIf="similarProducts.length > 0"
  [title]="'Related Products'"
  [items]="similarProducts"
  (openAddressDrawer)="openDrawer('addresses')"
  (openLoginDrawer)="openDrawer('login')"
/>
<!-- <div
  class="self-stretch flex flex-row items-center justify-start py-6 px-16 text-11xl text-gray-true-800"
>
  <b class="flex-1 relative leading-[38px]">Related Products</b>
</div>
<div
  class="self-stretch flex flex-row items-center justify-center pt-0 px-16 pb-12 gap-[32px] cursor-pointer text-lg"
  id="frameContainer2"
>
  <div
    class="flex-1 rounded-lg bg-base-white flex flex-col items-start justify-start pt-0 px-0 pb-3 gap-[4px] border-[1px] border-solid border-gray-2001"
    *ngFor="let product of relatedItems"  
  >
    <app-product-product [product]="product" />
  </div>
</div>
<div
  class="self-stretch flex flex-row items-center justify-start py-6 px-16 text-11xl text-gray-true-800"
>
  <b class="flex-1 relative leading-[38px]">Products From Same Brand</b>
</div>
<div
  class="self-stretch flex flex-row items-center justify-center pt-0 px-16 pb-12 gap-[32px] cursor-pointer text-lg"
  id="frameContainer4"
>
  <div
    class="flex-1 rounded-lg bg-base-white flex flex-col items-start justify-start pt-0 px-0 pb-3 gap-[4px] border-[1px] border-solid border-gray-2001"
    *ngFor="let product of sameBrandItems"  
  >
    <app-product-product [product]="product" />
  </div>
</div> -->
<!-- <div class="self-stretch flex flex-col items-center justify-start py-8 px-16 text-xl text-gray-900">
  <div class="self-stretch flex flex-row items-start justify-center gap-[48px]">
    <div class="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[240px]">
      <img class="w-[45.57px] relative h-12" alts="" src="assets/images/product/group.svg" />

      <div class="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <div class="self-stretch relative leading-[30px] font-semibold">Freshness Guaranteed</div>
        <div class="self-stretch relative text-base leading-[24px] text-gray-600">
          All your groceries are delivered in refrigerated trucks!
        </div>
      </div>
      <div
        class="overflow-hidden hidden flex-row items-center justify-center gap-[8px] text-center text-base text-brand-700">
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
      </div>
    </div>
    <div class="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[240px]">
      <img class="w-12 relative h-12 overflow-hidden shrink-0" alt=s"" src="assets/images/product/scooter.svg" />

      <div class="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <div class="self-stretch relative leading-[30px] font-semibold">Delivery 7 days a week</div>
        <div class="self-stretch relative text-base leading-[24px] text-gray-600">
          Pick your convenient date and time and we will deliver to your doorstep!
        </div>
      </div>
      <div
        class="overflow-hidden hidden flex-row items-center justify-center gap-[8px] text-center text-base text-brand-700">
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
      </div>
    </div>
    <div class="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[240px]">
      <img class="w-12 relative h-12 overflow-hidden shrink-0" alt="s" src="assets/images/product/giftbox.svg" />

      <div class="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <div class="self-stretch relative leading-[30px] font-semibold">Rewards</div>
        <div class="self-stretch relative text-base leading-[24px] text-gray-600">
          The more points you earn, the bigger your reward.
        </div>
      </div>
      <div
        class="overflow-hidden hidden flex-row items-center justify-center gap-[8px] text-center text-base text-brand-700">
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
      </div>
    </div>
    <div class="flex-1 flex flex-col items-start justify-start gap-[12px] min-w-[240px]">
      <img class="w-12 relative h-12 overflow-hidden shrink-0" alt="s" src="assets/images/product/satisfaction.svg" />

      <div class="self-stretch flex flex-col items-start justify-start gap-[4px]">
        <div class="self-stretch relative leading-[30px] font-semibold">Cashback</div>
        <div class="self-stretch relative text-base leading-[24px] text-gray-600">
          All your groceries aredelivered in refrigeratedtrucks!
        </div>
      </div>
      <div
        class="overflow-hidden hidden flex-row items-center justify-center gap-[8px] text-center text-base text-brand-700">
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
      </div>
    </div>
  </div>
</div> -->

<app-drawer [drawerId]="'productPageDrawer'" [isOpen]="isDrawerOpen" (reset)="reset()">
  <app-add-location
    *ngIf="isLocationOpen"
    (closeDrawer)="closeLocation($event)"
    (onSubmit)="saveLocation($event)"
  />
  <app-address-list
    *ngIf="isAddressesOpen"
    [eddressCode]="deliveryAddress?.eddressCode"
    (closeDrawer)="closeAddresses($event)"
    (newLocation)="openDrawer('location')"
    (confirmLocation)="getDeliveryAddress($event)"
  ></app-address-list>

  <app-login
    *ngIf="isLoginDrawerOpen"
    (isLoginOpen)="closeLogin($event)"
    (isSignupOpen)="openDrawer('signup')"
    (isForgotPasswordOpen)="openDrawer('forgotPassword')"
  />

  <app-signup
    *ngIf="isSignupDrawerOpen"
    (isSignupOpen)="closeSignup($event)"
    (isLoginOpen)="openDrawer('login')"
  />
  <app-forgot-password
    *ngIf="isForgotPasswordDrawerOpen"
    (isForgotPasswordOpen)="closeLogin($event)"
    (isLoginOpen)="openDrawer('login')"
  />
</app-drawer>
