<div class="p-4">
  <div
    class="flex-1 w-[360px] bg-white overflow-hidden flex flex-col items-center justify-start gap-[24px_0px] border-l-[1px] border-solid border-gray-200"
  >
    <div
      class="self-stretch flex flex-row items-start justify-start py-0 px-0 relative gap-[0px_8px]"
    >
      <div
        class="flex-1 flex flex-row items-center justify-start pt-3 px-0 pb-0 gap-[0px_16px] z-[0]"
      >
        <div class="flex-1 flex flex-col items-start justify-start gap-[4px_0px] text-11xl">
          <div class="self-stretch relative leading-[36px] font-semibold">
            {{ location ? "Edit Address" : "Add new Address" }}
          </div>
          <div class="w-[376px] relative text-sm leading-[20px] text-gray-600 hidden">
            Lorem ipsum dolor sit amet.
          </div>
        </div>
      </div>
      <div
        (click)="close()"
        class="w-10 my-0 mx-[!important] absolute top-[12px] right-[16px] rounded-lg h-10 overflow-hidden shrink-0 flex flex-row items-center justify-center p-2 box-border z-[1]"
      >
        <img
          class="w-6 relative h-6 overflow-hidden shrink-0 cursor-pointer"
          alt="close icon"
          src="assets/images/home/xclose.svg"
        />
      </div>
    </div>
  </div>
  <div class="self-stretch mt-4 flex flex-row items-center justify-center gap-[0px_6px]">
    <div class="flex-1 flex flex-col items-start justify-start gap-[6px_0px]">
      <div class="flex flex-row items-center justify-start w-full">
        <input
          *ngIf="showInput"
          id="pac-input"
          type="text"
          placeholder="Enter a location"
          class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
          [formControl]="searchControl"
        />
      </div>
    </div>
  </div>

  <div
    class="relative text-sm leading-[20px] font-text-md-semibold text-gray-700 text-left mt-[10px]"
  >
    Pin on Map
  </div>
  <div class="relative">
    <img *ngIf="isDraged" class="img-position" src="assets/images/home/app_pin.png" />
    <google-map
      (mapInitialized)="mapInitialize($event)"
      height="360px"
      width="100%"
      [options]="options"
      [center]="center"
      [zoom]="zoom"
      [draggable]="true"
      (mapClick)="addMarker($event)"
    >
      <map-marker [position]="markerPosition" [options]="markerOptions" />
    </google-map>
  </div>
  <form
    [formGroup]="locationFormGroup"
    class="m-0 self-stretch rounded-xl flex flex-col items-start justify-start gap-[24px_0px]"
  >
    <div class="self-stretch flex flex-col items-start justify-start gap-[20px_0px]">
      <div class="self-stretch flex flex-col items-start justify-start gap-[6px_0px]"></div>
      <div class="self-stretch flex flex-row items-center justify-center gap-[0px_6px]">
        <div class="flex-1 flex flex-col items-start justify-start gap-[6px_0px]">
          <div
            class="relative text-sm leading-[20px] font-text-md-semibold text-gray-700 text-left font-medium"
          >
            Address Name
          </div>
          <div class="flex flex-row items-center justify-start w-full">
            <input
              type="text"
              placeholder="Enter name of address"
              class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
              formControlName="name"
            />
          </div>
          <span
            *ngIf="locationForm['name'].invalid && locationForm['name'].touched"
            class="p-error"
          >
            <span class="text-error-500" *ngIf="locationForm['name'].errors['required']"
              >Address name is required</span
            >
          </span>
        </div>
      </div>
      <h2 class="text-lg font-semibold text-gray-700 mt-2">Additional Information</h2>
      <!-- Building Field -->
      <div class="self-stretch flex flex-row items-center justify-center gap-[0px_6px]">
        <div class="flex-1 flex flex-col items-start justify-start gap-[6px_0px]">
          <div
            class="relative text-sm leading-[20px] font-text-md-semibold text-gray-700 text-left font-medium"
          >
            Building
          </div>
          <div class="flex flex-row items-center justify-start w-full">
            <input
              type="text"
              placeholder="Enter building name"
              class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
              formControlName="building"
            />
          </div>

          <span
            *ngIf="locationForm['building'].invalid && locationForm['building'].touched"
            class="p-error"
          >
            <span class="text-error-500" *ngIf="locationForm['building'].errors['required']"
              >Buidling name is required</span
            >
          </span>
        </div>
      </div>

      <!-- Street Field -->
      <div class="self-stretch flex flex-row items-center justify-center gap-[0px_6px]">
        <div class="flex-1 flex flex-col items-start justify-start gap-[6px_0px]">
          <div
            class="relative text-sm leading-[20px] font-text-md-semibold text-gray-700 text-left font-medium"
          >
            Street
          </div>
          <div class="flex flex-row items-center justify-start w-full">
            <input
              type="text"
              placeholder="Enter street name"
              class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg border-gray-300 focus:outline-none px-2"
              formControlName="street"
            />
          </div>

          <span
            *ngIf="locationForm['street'].invalid && locationForm['street'].touched"
            class="p-error"
          >
            <span class="text-error-500" *ngIf="locationForm['street'].errors['required']"
              >Street name is required</span
            >
          </span>
        </div>
      </div>
    </div>
    <button
      type="button"
      [disabled]="isSubmitted"
      (click)="onSubmitFn()"
      class="cursor-pointer disabled:opacity-75 [border:none] p-0 bg-[transparent] self-stretch flex flex-row items-center justify-center"
    >
      <div
        class="flex-1 rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center p-2.5 gap-[0px_6px] border-[1px] border-solid border-brand-600"
      >
        <img class="h-5 w-5 relative overflow-hidden shrink-0 hidden" alt="hidden icon" />

        <div class="flex flex-row items-center justify-center py-0 pr-0 pl-0.5">
          <div class="flex flex-col items-start justify-start py-0 px-0">
            <div
              class="relative text-base leading-[24px] font-semibold font-text-md-semibold text-base-white text-left"
            >
              {{ location ? "Update Address" : "Save Address" }}
            </div>
          </div>
        </div>
        <img class="h-5 w-5 relative overflow-hidden shrink-0 hidden" alt="hidden icon" />
      </div>
    </button>
  </form>
</div>
