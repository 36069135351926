import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-landing-categories',
    templateUrl: './landing-categories.component.html',
    styleUrl: './landing-categories.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [NgIf, NgFor]
})
export class LandingCategoriesComponent implements OnInit {
  @Input() categories = [];
  @Input() sectionName;
  constructor(public router: Router) {}
  ngOnInit(): void {
    this.categories = this.categories.filter((c) => c.type === 'COLLECTION');
  }
  onCategoryClick(category) {
    this.router.navigate(['/category', category?.collection?.slug]);
  }
}
