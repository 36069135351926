export const Submenus = [
  {
    name: 'My Account',
    icon: 'assets/images/home/usercircle.svg',
    url: 'user/my-account',
    isActive: false,
  },
  {
    name: 'Orders',
    icon: 'assets/images/user/shoppingbag01.svg',
    url: 'user/orders',
    isActive: false,
  },
  {
    name: 'Favorites',
    icon: 'assets/images/user/heartrounded.svg',
    url: 'user/favorites',
    isActive: false,
  },
  {
    name: 'Addresses',
    icon: 'assets/images/user/markerpin03.svg',
    url: 'user/addresses',
    isActive: false,
  },
  {
    name: 'Payments',
    icon: 'assets/images/user/creditcardlock.svg',
    url: 'user/payments',
    isActive: false,
  },
  {
    name: 'My Wallet',
    icon: 'assets/images/user/wallet-02.svg',
    url: 'user/my-wallet',
    isActive: false,
  },
  {
    name: 'My Coupons',
    icon: 'assets/images/user/coupon-svg.svg',
    url: 'user/my-coupon',
    isActive: false,
  },
  {
    name: 'Refer a Friend',
    icon: 'assets/images/user/userplus01.svg',
    url: 'user/refer-friend',
    isActive: false,
  },
  {
    name: 'Logout',
    icon: 'assets/images/user/logout04.svg',
    url: 'logout',
    isActive: false,
  },
];
