import { NgFor } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Coupon } from '@app/core/models/coupon.model';
import { LoaderService } from '@app/core/services/loader.service';
import { CouponService } from './services/coupon.service';
import { AuthService } from '@app/core/services/auth.service';
import { CheckoutService } from '@app/checkout/services/checkout.service';
import { SweetAlertService } from '@app/core/services/sweetalert.service';
import { LocalStorageService } from '@app/core/services/localstorage.service';
import { PromoCodeType } from '@app/core/models/orderData.model';

@Component({
  selector: 'app-my-coupon',
  imports: [NgFor],
  templateUrl: './my-coupon.component.html',
  styleUrl: './my-coupon.component.scss',
})
export class MyCouponComponent implements OnInit {
  coupons: Coupon[] = [];
  deliveryAddress;
  selectedStore;
  public promoCodeType = PromoCodeType;
  constructor(
    private couponService: CouponService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private checkoutService: CheckoutService,
    private sweetAlertService: SweetAlertService,
    private localStorage: LocalStorageService
  ) {
    this.authService.deliveryAddress.subscribe((data) => {
      if (data) {
        this.deliveryAddress = data;
      }
    });
    this.authService.selectedStore.subscribe((data) => {
      if (data) {
        this.selectedStore = data;
      }
    });
  }

  ngOnInit() {
    this.getMyCoupons();
  }
  getMyCoupons() {
    this.loaderService.open();
    this.couponService
      .getMyCoupons({
        eddressCode: this.deliveryAddress.eddressCode,
        storeId: this.selectedStore.id,
      })
      .subscribe({
        next: (result: Coupon[]) => {
          this.coupons = result;
          console.log('🚀 ~ MyCouponComponent ~ getMyCoupons ~ result:', result);
          // this.coupons = [
          //   ...this.coupons,
          //   {
          //     id: 'dummy',
          //     couponvalue: '10% DISCOUNT',
          //     couponType: 'DELIVERY',
          //     promoCode: 'MHSN',
          //     description: 'Discount on delivery fees',
          //     expiryDateText: '14-Feb-2025, 02:00 AM',
          //     appliesOn: 'Applies to All stores',
          //     isActive: true,
          //   },
          // ];
          const promoCodeApplied = JSON.parse(this.localStorage.getItem('validPromoCode'));
          this.coupons.forEach((c) => {
            if (c.promoCode === promoCodeApplied?.promoCode) {
              c.isApplied = true;
            }
          });
          const walletCodeApplied = JSON.parse(this.localStorage.getItem('walletPromoCode'));
          this.coupons.forEach((c) => {
            if (c.promoCode === walletCodeApplied?.promoCode) {
              c.isWalletApplied = true;
            }
          });
          this.loaderService.close();
        },
        error: (error: any) => this.loaderService.close(),
        complete: () => console.log('Complete'),
      });
  }
  validatePromoCode(coupon) {
    const params = {
      // hasDelivery: this.hasDelivery,
      lat: this.deliveryAddress?.coordinates?.lat,
      lon: this.deliveryAddress?.coordinates?.lon,
      promo: coupon.promoCode,
      serviceUid: this.selectedStore.serviceProviderUid,
    };
    this.checkoutService.validatePromoCode(params).subscribe((result) => {
      this.getMyCoupons();
      const promoCodeApplied = JSON.parse(this.localStorage.getItem('validPromoCode'));
      if (result.message === 'valid') {
        if (promoCodeApplied && promoCodeApplied.promoCode === coupon.promoCode) {
          this.sweetAlertService.showAlert(
            'Oops...',
            `Coupon Code: ${promoCodeApplied.promoCode} is already applied.`,
            'error'
          );
        } else {
          this.coupons.forEach((c) => (c.isApplied = false));
          coupon.isApplied = true;
          if (result.promoType === PromoCodeType.DISCOUNT) {
            this.localStorage.setItem('validPromoCode', JSON.stringify(result));
          } else if (result.promoType === PromoCodeType.WALLET) {
            this.localStorage.setItem('walletPromoCode', JSON.stringify(result));
          }

          this.sweetAlertService
            .showAlert('Promo Code redeemed!', result.description, 'success')
            .then((result) => {});
        }
      } else {
        this.sweetAlertService.showAlert('Oops...', result.description, 'error');
      }
    });
  }
}
