import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { formatDate, NgIf, NgClass, NgFor } from '@angular/common';
import { COUNTRIES } from '@app/core/util/countries';
import { SearchPipe } from '../../../../shared/pipes/search.pipe';
import { environment } from '@environments/environment';
import cloneDeep from 'lodash/cloneDeep';

@Component({
    selector: 'app-edit-profile',
    templateUrl: './edit-profile.component.html',
    styleUrl: './edit-profile.component.scss',
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        NgClass,
        NgFor,
        SearchPipe,
    ]
})
export class EditProfileComponent implements OnInit {
  @Input() userDetails;

  @Output() closeDrawer = new EventEmitter<boolean>();
  @Output() onSubmit = new EventEmitter<boolean>();
  @Output() onDeleteAccount = new EventEmitter<boolean>();
  userProfileFormGroup: FormGroup;
  isSubmitted = false;

  open = false;
  searchValue = '';
  selectedCountry = COUNTRIES.countriesList[0];
  countries = COUNTRIES.countriesList;
  countryCode = environment.eddressAppConfig.countryCode;

  constructor(private formBuilder: FormBuilder) {}
  ngOnInit(): void {
    this.setDefaultCountry()
    this.userDetails = cloneDeep(this.userDetails)
    this.selectedCountry = this.findCountryCode(this.userDetails.phoneNumber);
    if (this.selectedCountry) {
      this.userDetails.phoneNumber = this.userDetails.phoneNumber.slice(
        this.selectedCountry?.dial_code?.length
      );
    }
    this._initUserProfileForm();
  }
  setDefaultCountry() {
    this.selectedCountry = this.countries.find(country => country.dial_code === this.countryCode);
  }
  _initUserProfileForm() {
    const processedBirthDate = this.userDetails?.birthDate?.replace(/\s/g, '');
    let formattedBirthDate;
    if (processedBirthDate) {
      formattedBirthDate = formatDate(processedBirthDate, 'yyyy-MM-dd', 'en-US');
    }

    this.userProfileFormGroup = this.formBuilder.group({
      fullName: [this.userDetails.fullName ? this.userDetails.fullName : '', [Validators.required]],
      email: [
        this.userDetails.email ? this.userDetails.email : '',
        [Validators.required, Validators.email],
      ],
      phoneNumber: [
        this.userDetails.phoneNumber ? this.userDetails.phoneNumber : '',
        [Validators.required],
      ],
      birthDate: [formattedBirthDate ? formattedBirthDate : '', [Validators.required]],
    });
  }
  toggleDropdown() {
    this.open = !this.open;
  }
  selectCountry(country) {
    this.selectedCountry = country;
    this.open = false;
    this.searchValue = '';
  }
  findCountryCode(phoneNumber) {
    const countries = cloneDeep(this.countries);
    const sortedCountryCodes = countries.sort((a, b) => {
      return b?.dial_code?.length - a?.dial_code?.length;
    });
    for (const country of sortedCountryCodes) {
      if (phoneNumber.startsWith(country.dial_code)) {
        return country;
      }
    }

    return null; // No matching country code found
  }
  onSubmitFn() {
    this.isSubmitted = true;
    const val = this.userProfileFormGroup.value;

    if (this.userProfileFormGroup.valid) {
      this.onSubmit.emit(val);
      this.closeDrawer.emit(false);
    }
  }
  close() {
    this.closeDrawer.emit(false);
  }
  deletAccount() {
    this.onDeleteAccount.emit();
  }
  get userAccountForm() {
    return this.userProfileFormGroup.controls;
  }
}
