.submenu {
  position: absolute;
  inset: auto auto auto auto !important;
  margin: 0px;
  transform: translate3d(0px, 0px, 0px) !important;
}

.z-100 {
  z-index: 100;
}
#menuMobileDelay {
  transform: translate3d(0px, 78px, 0px) !important;
}

