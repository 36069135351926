import { LayoutServiceService } from './../layout/services/layout-service.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '@environments/environment';
import { InnerContentComponent } from './components/inner-content/inner-content.component';
import { GenericService } from '@app/core/services/generics.service';

@Component({
    selector: 'app-content-page',
    templateUrl: './content-page.component.html',
    styleUrl: './content-page.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [InnerContentComponent]
})
export class ContentPageComponent implements OnDestroy {
  contentPage;

  constructor(
    public route: ActivatedRoute,
    private layoutService: LayoutServiceService,
    private titleService: Title,
    private genericService: GenericService
  ) {
    this.route.params.subscribe((params) => {
      const slug = params['slug'];
      this.layoutService
        .getContentPageDetails(slug, environment.eddressAppConfig.appName)
        .subscribe((data) => {
          this.contentPage = data;
          this.titleService.setTitle(this.contentPage.title);
          // this.genericService.setPageTitleAndDescription('contents', this.contentPage.title);
          setTimeout(() => {
            if (this.contentPage?.metaDescription) {
              this.genericService.setPageMetaDescription(this.contentPage?.metaDescription);
            }
          }, 1000);

          this.genericService.setPageMetaKeywords(this.contentPage?.metaKeywords);
        });
    });
  }
  ngOnDestroy() {
    this.genericService.setPageMetaKeywords('');
    this.genericService.setPageMetaDescription('');
  }
}
