<div class="flex-1 flex flex-col items-start justify-start gap-[12px_0px] text-xl text-gray-900">
  <h1 class="relative leading-[30px] font-semibold">My Coupons</h1>
  <div
    class="self-stretch grid md:grid-cols-3 items-start md:justify-start gap-[12px_12px] text-sm text-gray-600"
  >
    <div
      *ngFor="let coupon of coupons"
      class="flex-1 min-h-[150px] rounded-lg bg-base-white flex flex-col items-start justify-start border-[1px] border-solid border-gray-201"
    >
      <div class="self-stretch flex flex-col items-start justify-start py-3 px-6 gap-[10px_0px]">
        <div class="flex flex-row items-center justify-start gap-[0px_10px]">
          <div class="relative leading-[20px] font-semibold whitespace-nowrap">
            {{ coupon?.description }} @if(coupon?.isApplied || coupon?.isWalletApplied) {
            <span
              class="bg-green-100 text-green-400 text-xs font-medium me-2 px-2.5 py-0.5 rounded-xl dark:bg-green-900 dark:text-green-300"
              >Applied</span
            >
            }
          </div>
        </div>
        <div class="relative leading-[20px]">
          <p class="[margin-block-start:0]">
            {{ coupon?.details }}
          </p>
        </div>

        <!-- <div class="relative leading-[20px]">
          @if(coupon?.stores){<span class="mr-1">Applies to:</span>} @for (store of coupon?.stores;
          track store.uid) {
          <span
            class="bg-brand-200 text-brand-700 text-xs font-medium me-1 px-2.5 py-0.5 rounded-xl dark:bg-green-900"
            >{{ store.name }}</span
          >
          } @empty {
          <p class="[margin-block-start:0]">Applies to All stores</p>
          }
        </div> -->

        <div class="relative leading-[20px]">
          <p class="[margin-block-start:0]">Valid until: {{ coupon?.expiryDateLabel }}</p>
        </div>
        <div
          class="flex flex-row items-start justify-start gap-[0px_12px] text-brand-700"
          (click)="validatePromoCode(coupon)"
        >
          <div
            class="rounded-lg overflow-hidden flex flex-row items-center justify-center py-2.5 px-0 gap-[0px_4px] cursor-pointer"
          >
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />

            <div class="flex flex-row items-center justify-center">
              @if(!coupon.isApplied || coupon?.isWalletApplied) {
              <div class="relative leading-[20px] font-semibold whitespace-nowrap">Redeem Now</div>
              }
            </div>
            <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
@if(coupons?.length === 0) {
<div>
  <div class="flex-1 flex flex-col items-center">
    <div class="flex-grow text-center">
      <img
        class="w-24 h-24 ml-[72px] relative overflow-hidden shrink-0"
        alt="shopping bag icon"
        src="assets/images/user/coupon-svg.svg"
        id="ordersIcon"
      />
      <h1 class="font-bold text-5xl">Coupons</h1>
      <span class="text-lg">Your coupons will appear here.</span>
    </div>
  </div>
</div>
}
