<div class="relative text-xl leading-[30px] font-semibold mb-4">
  {{ title }}
</div>
<div
  *ngFor="let order of orders"
  class="self-stretch rounded-lg bg-base-white flex flex-col items-start justify-start border-[1px] border-solid border-gray-2001 mb-5"
>
  <div class="self-stretch flex flex-col items-start justify-start gap-[20px_0px]">
    <div
      class="self-stretch flex flex-col md:flex-row items-start justify-start pt-5 px-6 pb-0 gap-[16px_16px] z-[1]"
    >
      <div class="self-stretch flex-1 flex flex-col items-start justify-center gap-[4px_0px]">
        <div class="self-stretch flex flex-row items-center justify-start gap-[0px_8px]">
          <div class="relative leading-[28px] font-semibold">Order #{{ order?.sequence }}</div>
          <div
            class="rounded-md bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] hidden flex-row items-center justify-start py-0.5 px-1.5 gap-[0px_4px] text-center text-xs text-gray-700 border-[1px] border-solid border-gray-300"
          >
            <img class="w-2 relative h-2" alt="online icon" src="assets/images/user/-dot.svg" />

            <div class="relative leading-[18px]">Online</div>
          </div>
        </div>
      </div>
      <div class="flex flex-row items-center justify-start gap-[0px_12px] text-sm text-base-white">
        <div
          (click)="reOrderFn(order)"
          class="rounded-lg overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-brand-700 cursor-pointer"
          *ngIf="
            order?.orderStatus === 'COMPLETE' ||
            order.orderStatus === 'NEW_CANCELED' ||
            order.orderStatus === 'CANCELED' ||
            order.orderStatus === 'DONE'
          "
        >
          <img
            class="w-5 relative h-5 overflow-hidden shrink-0"
            alt="repeat icon"
            src="assets/images/user/repeat03.svg"
          />

          <div class="flex flex-row items-center justify-center py-0 px-0.5">
            <div class="relative leading-[20px] font-semibold">Reorder</div>
          </div>
          <img
            class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
            alt="hidden icon"
            src="assets/images/user/placeholder.svg"
          />
        </div>

        <div
          (click)="viewOrderFn(order)"
          [ngClass]="
            title === 'Past Orders' ? 'bg-white text-gray-900' : 'bg-brand-600 border-brand-600'
          "
          class="rounded-lg shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] border-[1px] border-solid cursor-pointer"
        >
          <div class="flex flex-row items-center justify-center py-0 px-0.5">
            <div class="relative leading-[20px] font-semibold">View Order</div>
          </div>
          <img
            class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
            alt="hidden icon"
            src="assets/images/user/placeholder.svg"
          />
        </div>
      </div>
    </div>
    <img
      class="self-stretch relative max-w-full overflow-hidden h-px shrink-0 z-[0]"
      alt="divider icon"
      src="assets/images/user/divider.svg"
    />
  </div>
  <div class="self-stretch flex flex-col items-start justify-start py-3 px-6 text-sm text-gray-600">
    <div class="self-stretch flex flex-col md:flex-row items-start md:justify-between">
      <div
        class="w-[177px] box-border h-[52px] flex flex-col items-start justify-start gap-[4px_0px] min-w-[160px] md:border-r-[1px] border-solid border-gray-300"
      >
        <div class="self-stretch relative leading-[20px]">Date</div>
        <div class="self-stretch relative leading-[20px] font-semibold">
          {{ formatDate(order?.createdOn) }}
        </div>
      </div>
      <div
        class="flex-1 box-border h-[52px] flex flex-col items-start justify-start py-0 md:px-3 gap-[4px_0px] min-w-[160px] md:border-r-[1px] border-solid border-gray-300"
      >
        <div class="relative leading-[20px]">Delivery Address</div>
        <div
          class="w-[236.81px] relative leading-[20px] font-semibold inline-block h-[29.72px] shrink-0"
        >
          {{ order?.addressName || order?.delivery?.addressName }}
        </div>
      </div>
      <div
        class="w-[197px] box-border h-[52px] flex flex-col items-start justify-start py-0 md:px-3 gap-[4px_0px] min-w-[160px] md:border-r-[1px] border-solid border-gray-300"
      >
        <div class="relative leading-[20px]">Order Status</div>
        <div
          class="rounded-md bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] flex flex-row items-center justify-start py-0.5 px-1.5 gap-[0px_4px] text-center text-xs text-gray-700 border-[1px] border-solid border-gray-300"
        >
          <img class="w-2 relative h-2" alt="status icon" src="assets/images/user/-dot.svg" />

          <div class="relative leading-[18px]">{{ order?.orderStatus | orderStatus }}</div>
        </div>
      </div>
      <div
        class="w-[214px] h-[52px] flex flex-col items-start justify-start py-0 md:px-3 box-border gap-[4px_0px] min-w-[160px]"
      >
        <div class="relative leading-[20px]">Order Total</div>
        <div class="self-stretch relative leading-[20px] font-semibold">
          {{ order?.currencyIso || order?.currency }} {{ order?.amountDue | number : "1.2-2" }}
        </div>
      </div>
    </div>
  </div>
</div>
