import {
  AfterViewInit,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CategoryService } from './services/category.service';
import { LoaderService } from '@app/core/services/loader.service';
import { AddressesService } from '@app/user-information/addresses/services/addresses.service';
import { NotificationService } from '@app/core/services/notification.service';
import { AuthService } from '@app/core/services/auth.service';
import { environment } from '@environments/environment';
import { GenericService } from '@app/core/services/generics.service';
import { Title } from '@angular/platform-browser';
import { ForgotPasswordComponent } from '../layout/header/components/forgot-password/forgot-password.component';
import { SignupComponent } from '../layout/header/components/signup/signup.component';
import { LoginComponent } from '../layout/header/components/login/login.component';
import { AddressListComponent } from '../shared/components/address-list/address-list.component';
import { AddLocationComponent } from '../shared/components/add-location/add-location.component';
import { MoreFiltersComponent } from './components/more-filters/more-filters.component';
import { DrawerComponent } from '../shared/drawer/drawer.component';
import { ProductItemComponent } from '../shared/product-item/product-item.component';
import { NgIf, NgFor } from '@angular/common';
import { OuterFilterComponent } from './components/outer-filter/outer-filter.component';
import { RangeFilterComponent } from '../shared/components/range-filter/range-filter.component';
import { TitleCasePipe } from '@angular/common';
import orderBy from 'lodash/orderBy';
import omit from 'lodash/omit';

@Component({
    selector: 'app-category',
    templateUrl: './category.component.html',
    styleUrl: './category.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [
        RangeFilterComponent,
        OuterFilterComponent,
        NgIf,
        NgFor,
        ProductItemComponent,
        DrawerComponent,
        MoreFiltersComponent,
        AddLocationComponent,
        AddressListComponent,
        LoginComponent,
        SignupComponent,
        ForgotPasswordComponent,
    ],
    providers: [TitleCasePipe]
})
export class CategoryComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:scroll', [])
  onWindowScroll() {
    if (window.pageYOffset > 100) {
      this.isVisible = true;
    } else {
      this.isVisible = false;
    }
    // if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 300 && !this.isLoading && this.showMoreBtn) {
    //   this.loadMore()
    // }
  }
  isLoading = false;
  queryParams: any = {
    maxPrice: 0,
    minPrice: 0,
    brandIds: '',
    groups: '',
    pageSize: 20,
  };
  value: number = 100;
  items: any[] = [];
  brandsFilters = [];
  priceFilters = [
    {
      label: 'Less than 15',
      minvalue: 0,
      maxvalue: 15,
    },
    {
      label: 'Between $15 and 100$',
      minvalue: 15,
      maxvalue: 100,
    },
    {
      label: 'Between $100 and 200$',
      minvalue: 100,
      maxvalue: 200,
    },
  ];
  sortFilters: any = [
    {
      label: 'Alphabetically, A-Z',
      value: 'NAME_ASC',
    },
    {
      label: 'Alphabetically, Z-A',
      value: 'NAME_DESC',
    },
    {
      label: 'Price, low to high',
      value: 'PRICE_ASC',
    },
    {
      label: 'Price, high to low',
      value: 'PRICE_DESC',
    },
  ];
  collectionGroups = [];
  homePageData;
  user;
  recordCount = 0;
  showMoreBtn = false;
  isVisible = false;

  isDrawerOpen = false;
  isFilterOpen = false;
  isLocationOpen: boolean = false;
  isAddressesOpen: boolean = false;
  isLoginDrawerOpen: boolean = false;
  isSignupDrawerOpen: boolean = false;
  isForgotPasswordDrawerOpen: boolean = false;
  pageLoaded = false;
  deliveryAddress;

  clickedFilter = '';

  selectedFilters = [];

  selectedPriceFiltersLabel = '';
  selectedBrandsFilters = [];
  selectedGroupsFilters = [];
  selectedSortFilters = [];

  removedFilters = [];
  category: any = {};
  selectedStore;
  currentPage;
  apiResponse = false;
  configuration;
  categoryLabel;
  slug = '';
  groupSlug = '';

  constructor(
    private route: ActivatedRoute,
    private categoryService: CategoryService,
    private loaderService: LoaderService,
    private addressesService: AddressesService,
    private notification: NotificationService,
    private authService: AuthService,
    private genericService: GenericService,
    private titleService: Title,
    // private titleCasePipe: TitleCasePipe,
    public router: Router
  ) {
    this.route.params.subscribe((param) => {
      this.slug = param['slug'];
      this.groupSlug = param['group'];
      if (this.groupSlug) {
        this.groupSlug = this.groupSlug
          .replace(/-/g, ' ') // Replace hyphens with spaces
          .split(' ') // Split into words
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Title case each word
          .join(' '); // Join words back with spaces
      }
      this.queryParams = {
        maxPrice: 0,
        minPrice: 0,
        brandIds: '',
        pageSize: 20,
        groups: this.groupSlug,
      };
      this.selectedPriceFiltersLabel = '';
      this.selectedBrandsFilters.length = 0;
      this.selectedGroupsFilters.length = 0;
      this.selectedSortFilters.length = 0;
      this.sortFilters.forEach((s) => (s.selected = false));
      this.getProductsByCategory();
    });
    this.authService.currentUser.subscribe((data) => {
      if (Object.keys(data).length !== 0) {
        this.user = data;
      }
    });
    this.genericService.getConfigurations().subscribe((result) => {
      this.configuration = result;
    });
    this.authService.selectedStore.subscribe((data) => {
      if (data) {
        this.selectedStore = data;
        this.getProductsByCategory();
      }
    });
    this.authService.homePageData.subscribe((data) => {
      if (data) {
        this.homePageData = data;
        this.getBrandsAndGroups();
      }
    });
  }

  ngOnInit() {
    this.genericService.checkCanonicalTag();
  }

  ngAfterViewInit(): void {
    this.pageLoaded = true;
  }
  openDrawer(type) {
    this.isDrawerOpen = true;

    this.isLoginDrawerOpen = false;
    this.isSignupDrawerOpen = false;
    this.isForgotPasswordDrawerOpen = false;
    this.isFilterOpen = false;

    if (type === 'login') {
      this.isLoginDrawerOpen = true;
    } else if (type === 'signup') {
      this.isSignupDrawerOpen = true;
      this.isLoginDrawerOpen = false;
    } else if (type === 'forgotPassword') {
      this.isForgotPasswordDrawerOpen = true;
      this.isLoginDrawerOpen = false;
    } else if (type === 'filter') {
      this.isFilterOpen = true;
    }
  }
  openDrawerLoc(type) {
    this.isDrawerOpen = true;

    this.isLocationOpen = false;
    this.isAddressesOpen = false;
    this.isLoginDrawerOpen = false;
    this.isFilterOpen = false;
    if (type === 'location') {
      this.isLocationOpen = true;
    } else if (type === 'addresses') {
      this.isAddressesOpen = true;
    } else if (type === 'login') {
      this.isLoginDrawerOpen = true;
    } else if (type === 'signup') {
      this.isSignupDrawerOpen = true;
    } else if (type === 'forgotPassword') {
      this.isForgotPasswordDrawerOpen = true;
    }
  }

  closeLogin($event) {
    this.isDrawerOpen = false;
    this.isLoginDrawerOpen = $event;
  }

  closeSignup($event) {
    this.isDrawerOpen = false;
    this.isSignupDrawerOpen = $event;
  }

  closeForgotPassword($event) {
    this.isDrawerOpen = false;
    this.isForgotPasswordDrawerOpen = $event;
  }

  reset() {
    this.isDrawerOpen = false;
    const backdrop = document.getElementsByClassName('back-drop');
    for (var i = 0; i < backdrop.length; i++) {
      const el: any = backdrop[i];
      el.style.display = 'none';
    }
  }

  closeLocation($event) {
    this.isDrawerOpen = false;
    this.isLocationOpen = $event;
  }
  closeAddresses($event) {
    this.isDrawerOpen = false;
    this.isLocationOpen = $event;
  }

  onUpdateProduct(product) {
    this.genericService.updateHomepageProducts(product, '');
  }

  addPriceFilters(event) {
    const priceFilterLabel =
      'Between ' +
      this.configuration.tenantCurrencySymbol +
      event.minvalue +
      ' and ' +
      event.maxvalue +
      this.configuration.tenantCurrencySymbol;
    this.selectedPriceFiltersLabel = priceFilterLabel;
    this.queryParams.minPrice = event.minvalue;
    this.queryParams.maxPrice = event.maxvalue;
    this.getProductsByCategory();
  }

  addBrandsFilters(event) {
    this.selectedBrandsFilters = event;
    this.queryParams.brandIds = event.map((e) => e.value).join(',');
    this.getProductsByCategory();
  }
  addGroupsFilters(event) {
    this.selectedGroupsFilters = event;
    this.queryParams.groups = event.map((e) => e.label).join(',');
    this.getProductsByCategory();
  }
  addSortFilters(event) {
    this.selectedSortFilters = [event];
    this.queryParams.sortBy = event.value;
    this.getProductsByCategory();
  }
  removePriceFilters() {
    this.selectedPriceFiltersLabel = '';
    this.queryParams = omit(this.queryParams, 'minPrice');
    this.queryParams = omit(this.queryParams, 'maxPrice');
    this.getProductsByCategory();
  }
  removeBrandFilters(brand) {
    this.selectedBrandsFilters = this.selectedBrandsFilters.filter((b) => b.value !== brand.value);
    this.queryParams.brandIds = this.selectedBrandsFilters.map((e) => e.value).join(',');
    this.getProductsByCategory();
  }
  removeGroupsFilters(group) {
    this.selectedGroupsFilters = this.selectedGroupsFilters.filter((b) => b.value !== group.value);
    this.queryParams.groups = this.selectedGroupsFilters.map((e) => e.label).join(',');
    if (this.queryParams.groups || !this.groupSlug) {
      this.getProductsByCategory();
    } else {
      this.router.navigate(['/category', this.slug]);
    }
  }
  removeSortFilters(brand) {
    this.selectedSortFilters = this.selectedSortFilters.filter((b) => b.value !== brand.value);
    this.queryParams.sortBy = '';
    this.sortFilters.forEach((s) => (s.selected = false));
    this.getProductsByCategory();
  }
  applyMoreFilter(event) {
    this.selectedBrandsFilters = event.selectedBrands;
    this.queryParams.brandIds = this.selectedBrandsFilters.map((e) => e.value).join(',');

    this.selectedGroupsFilters = event.selectedgroups;
    this.queryParams.groups = this.selectedGroupsFilters.map((e) => e.label).join(',');
    if (this.queryParams.groups || !this.groupSlug) {
      this.getProductsByCategory();
      this.reset();
    } else {
      this.reset();
      this.router.navigate(['/category', this.slug]);
    }
  }

  getProductsByCategory() {
    if (this.selectedStore) {
      const obj = {
        storeId: this.selectedStore?.id,
        slug: this.slug,
      };
      if (this.queryParams.minPrice === 0 && this.queryParams.maxPrice === 0) {
        this.queryParams = omit(this.queryParams, 'minPrice');
        this.queryParams = omit(this.queryParams, 'maxPrice');
      }
      if (!this.queryParams.brandIds) {
        this.queryParams = omit(this.queryParams, 'brandIds');
      }
      if (!this.queryParams.groups) {
        this.queryParams = omit(this.queryParams, 'groups');
      }
      if (!this.queryParams.sortBy) {
        this.queryParams = omit(this.queryParams, 'sortBy');
      }
      this.isLoading = true;
      this.loaderService.open();
      this.categoryService.getProductsByCollections(obj, this.queryParams).subscribe(
        (result) => {
          this.setCategory(result);
        },
        (error) => {
          this.apiResponse = true;
          this.items.length = 0;
          this.loaderService.close();
          this.isLoading = false;
        }
      );
    }
  }

  setCategory(result) {
    this.category = result.collection;
    if (this.selectedGroupsFilters.length === 0) {
      const group = result.groups
        .filter((g) => g.label === this.groupSlug)
        .map((v) => {
          return { value: v.id, label: v.label };
        });
      this.selectedGroupsFilters = group;
    }
    this.titleService.setTitle(
      result?.collection?.label + ' | ' + environment.eddressAppConfig.companyTitle
    );
    if (result?.collection?.websiteTitle) {
      this.titleService.setTitle(result?.collection?.websiteTitle);
    } else {
      // this.genericService.setPageTitleAndDescription('category', result?.collection?.label);
    }
    setTimeout(() => {
      if (result?.collection?.websiteMetaDescription) {
        this.genericService.setPageMetaDescription(result?.collection?.websiteMetaDescription);
      }
    }, 1000);

    this.genericService.setPageMetaKeywords(result?.collection?.websiteMetaKeywords);
    // if (result?.brands) {
    //   this.brandsFilters = result?.brands?.map((b) => {
    //     const obj = {
    //       label: b.name,
    //       value: b.id,
    //     };
    //     return obj;
    //   });
    // }
    this.getBrandsAndGroups();
    this.apiResponse = true;
    this.loaderService.close();
    this.isLoading = false;
    this.sortProducts(result);
  }
  sortProducts(result) {
    this.recordCount = result.recordCount;
    this.showMoreBtn = this.queryParams.pageSize <= this.recordCount ? true : false;
    this.items.length = 0;
    this.items = result?.productsList;

    // result.productsList = _.orderBy(result?.productsList, ['label'], 'asc');
    // result?.productsList.forEach((product) => {
    //   const collectionProduct = result?.collectionItems.find((p) => p.idProduct === product.id);
    //   if (collectionProduct) product.sortOrder = collectionProduct.sortOrder;
    // });

    // this.items = result?.productsList;
    // this.items = _.orderBy(this.items, ['sortOrder'], 'asc');
    // result.groups = _.orderBy(result?.groups, ['label'], 'asc');
    // result.groups = _.orderBy(result?.groups, ['sortOrder'], 'asc');
    // let sortedItemByGroups = [];
    // result.groups.forEach((group) => {
    //   this.items.forEach((item) => {
    //     let productCollections = item.productCollections.map((c) => c.group);
    //     let combineGroups = [];
    //     productCollections.forEach(c => {
    //       const groups = c.split(',')
    //       if(groups.length > 1) {
    //         combineGroups = groups
    //       }
    //     })
    //     if(combineGroups.length > 1) productCollections = combineGroups
    //     if (productCollections.includes(group.label)) {
    //       sortedItemByGroups.push(item);
    //     }
    //   });
    // });
    // const uniqueSortedItemByGroups = sortedItemByGroups.filter((value, index, self) =>
    //   index === self.findIndex((t) => (
    //     t.id === value.id
    //   ))
    // );
    // this.items = uniqueSortedItemByGroups;
  }
  getBrandsAndGroups() {
    this.brandsFilters = this.homePageData?.brands.map((b) => {
      const obj = {
        label: b.name,
        value: b.id,
      };
      return obj;
    });
    if (this.homePageData?.collectionGroups) {
      const collectionGroups = [];
      for (let [_, value] of Object.entries(this.homePageData?.collectionGroups)) {
        if (value['idCollection'] === this.category?.id) {
          const obj = {
            label: value['label'],
            value: value['id'],
            sortOrder: value['sortOrder'],
          };
          if (this.selectedGroupsFilters.length > 0) {
            const selectedGroup = this.selectedGroupsFilters.find((g) => g.value === obj.value);
            if (selectedGroup) {
              obj['selected'] = selectedGroup.selected;
            }
          }
          collectionGroups.push(obj);
        }
      }
      this.collectionGroups = collectionGroups;
      this.collectionGroups = orderBy(this.collectionGroups, ['sortOrder'], 'asc');
    }
  }
  getDeliveryAddress(deliveryAddress) {
    if (this.deliveryAddress !== deliveryAddress) {
      window.location.reload();
    }
    this.deliveryAddress = deliveryAddress;
    this.authService.setUserDeliveryAddress(this.deliveryAddress);
  }
  saveLocation(location) {
    const params = {
      address: {
        locality: location.locality,
        state: '',
        uid: this.user.uid,
        version: '3.1.9',
        street: location.street,
        building: location.building,
        locale: 'es',
        os: 'web',
        type: '',
        appName: environment.eddressAppConfig.appName,
        coordinates: location.coordinates,
        city: '',
        phoneCountryCode: '0',
        idUser: this.user.idUser,
        phoneCountryId: '0',
        notes: '',
        name: location.name,
        creationLat: location.coordinates.lat,
        creationLon: location.coordinates?.lon,
      },
    };
    this.loaderService.open();
    this.addressesService.saveUserAddress(params).subscribe(
      () => {
        this.loaderService.close();
        this.notification.successNotification('Address saved successfully');
        this.closeLocation(true);
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }
  changePage(delta: number): void {
    // some checks
    this.currentPage += delta;
  }
  loadMore() {
    this.queryParams.pageSize += 20;
    this.getProductsByCategory();
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
  ngOnDestroy(): void {
    this.genericService.setPageMetaKeywords('');
    this.genericService.setPageMetaDescription('');
  }
}
