import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { NgFor, NgClass } from '@angular/common';
import { environment } from '@environments/environment';
import { Submenus } from '@app/core/constants/submenu.constants';

@Component({
  selector: 'app-user-information',
  templateUrl: './user-information.component.html',
  styleUrl: './user-information.component.scss',
  imports: [NgFor, NgClass, RouterOutlet],
})
export class UserInformationComponent {
  submenus = Submenus;
  constructor(public router: Router, private authService: AuthService) {
    if (environment.eddressAppConfig.paymentGateway === 'ADYENDROPIN') {
      this.submenus = this.submenus.filter((m) => m.name !== 'Payments');
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.substring(1);
        if (url === 'user/my-wallet') {
          this.submenus.forEach((m) => {
            m.isActive = false;
            if (m.url === url) {
              m.isActive = true;
            }
          });
        }
      }
    });
  }

  onClickMenu(menu) {
    this.submenus.forEach((m) => (m.isActive = false));
    menu.isActive = true;
    if (menu.url !== 'logout') {
      this.router.navigate([menu.url]);
    } else {
      this.logout();
    }
  }

  logout() {
    this.authService.removeUserAuthentication();
    localStorage.clear();
    this.router.navigateByUrl('/');
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}
