<div class="w-full flex-1 flex flex-col items-start justify-start gap-[12px_0px] text-gray-900">
  <h1 class="relative leading-[24px] font-semibold">Refer a Friend</h1>
  <div
    class="w-full rounded-lg bg-base-white box-border h-[303px] flex flex-col items-center justify-between p-6 text-center text-sm border-[1px] border-solid border-gray-201"
  >
    <img
      class="w-[53px] relative h-[53px] overflow-hidden shrink-0"
      alt="like icon"
      src="assets/images/user/like.svg"
    />

    <div class="w-full relative text-base leading-[24px] font-semibold inline-block">
      Earn extra cash by referring a friend! 🌟
    </div>
    <div
      *ngIf="referralDetail?.referralSharedMessage"
      class="md:w-[365.45px] relative leading-[20px] inline-block"
    >
      {{ referralDetail?.referralSharedMessage }}
    </div>
    <div
      class="w-[47px] rounded-md bg-base-white shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] box-border hidden flex-row items-center justify-start py-0.5 px-1.5 gap-[0px_4px] text-xs text-gray-700 border-[1px] border-solid border-gray-300"
    >
      <img class="w-2 relative h-2" alt="online icon" src="assets/images/user/-dot.svg" />

      <div class="relative leading-[18px]">Online</div>
    </div>
    <!-- <a
      [href]="referralDetail?.inviteLink"
      target="_blank"
      class="rounded-lg bg-brand-600 shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-2.5 px-3.5 gap-[0px_4px] text-left text-base-white border-[1px] border-solid border-brand-600 cursor-pointer"
    >
      <img
        class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
        alt="hidden icon"
        src="assets/images/user/phonecall01.svg"
      />

      <div class="flex flex-row items-center justify-center py-0 px-0.5">
        <div class="relative leading-[20px] font-semibold">Share It With Friends</div>
      </div>
      <img
        class="w-5 relative h-5 overflow-hidden shrink-0 hidden"
        alt="hidden icon"
        src="assets/images/user/placeholder.svg"
      />
    </a> -->

    <div class="relative">
      <div
        class="rounded-lg shadow-[0px_1px_2px_rgba(16,_24,_40,_0.05)] overflow-hidden flex flex-row items-center justify-center py-3 px-[18px] gap-[6px] border-[1px] border-solid border-brand-300 cursor-pointer"
        (click)="toggleShareDropdown()"
      >
        <img
          class="w-5 relative h-5 overflow-hidden shrink-0"
          alt="share icon"
          src="assets/images/product/share02.svg"
        />

        <div class="flex flex-row items-center justify-center py-0 px-0.5">
          <div class="relative leading-[24px] font-semibold">Share It With Friends</div>
        </div>
        <img class="w-5 relative h-5 overflow-hidden shrink-0 hidden" alt="hidden icon" />
      </div>

      <div
        class="absolute z-10 top-full right-0 mt-2 w-48 rounded-md shadow-lg cursor-pointer bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100"
        *ngIf="isShareDropdownOpen"
        id="shareDropdown"
        style="right: 0"
      >
        <div class="py-1">
          <share-buttons
            [include]="['facebook', 'whatsapp', 'x', 'email']"
            [showText]="false"
            theme="material-dark"
            show="11"
            [url]="referralDetail?.inviteLink"
            [description]="referralDetail?.referralSharedMessageWeb"
          />
        </div>
      </div>
    </div>
    <div class="flex flex-row items-center justify-start gap-[0px_8px] text-left text-gray-600">
      <div class="relative leading-[20px] overflow-hidden text-ellipsis whitespace-nowrap">
        Or share your code
      </div>
      <div
        id="tooltipContent"
        role="tooltip"
        class="absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-0 tooltip dark:bg-gray-700"
      >
        {{ toolTipMessage }}
        <!-- <div class="tooltip-arrow"></div> -->
      </div>
      <div
        class="overflow-hidden flex flex-row items-center justify-center gap-[0px_6px]"
        id="tooltipButton"
      >
        <div class="relative leading-[20px] font-semibold">{{ referralDetail?.promoCode }}</div>
        <img
          (click)="copyMessage(referralDetail?.promoCode)"
          class="w-5 relative h-5 overflow-hidden shrink-0 cursor-pointer"
          alt="copy promocode icon"
          src="assets/images/user/copy07.svg"
        />
      </div>
    </div>
  </div>
</div>
