.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

section .section-title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 50px;
    color: #404040;
}
section .section-para {
    font-size: 18px;
    font-weight: 500;
    color: #404040;
    line-height: 30px;
    max-width: 860px;
    margin: 0 auto 80px;
}
section .section-title::after {
    content: '';
    width: 90px;
    height: 4px;
    border-radius: 5px;
    display: block;
    margin: 10px auto;
    background-color: #404040;
}
.about-wrap .option-wrap {
    background-color: #ffffff;
    padding: 60px 35px;
    border-radius: 45px;
    height: 100%;
    transition: all 0.3s;
}
.about-wrap .option-wrap:hover {
    box-shadow: 0 0 70px 0 rgba(0, 0, 0, 0.15);
}