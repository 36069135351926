import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ShowAlertService } from '@app/shared/services/showalerts.service';

@Component({
    selector: 'app-show-alert',
    imports: [],
    templateUrl: './show-alert.component.html',
    styleUrl: './show-alert.component.scss'
})
export class ShowAlertComponent {
  @Input() alert;
  @Input() contentId;
  @Output() closeModal = new EventEmitter<boolean>();
  currentAlert;

  constructor(private alertService: ShowAlertService) {
    this.alertService.currentAlert$.subscribe((alert) => {
      this.currentAlert = alert;
    });
  }
  close() {
    if (this.alertService.alertQueue.length === 0) this.closeModal.emit(false);
    this.alertService.closeAlert();
  }
}
