import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Urls } from '@app/core/util/urls';
import { BehaviorSubject, Observable, distinctUntilChanged, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  private productUrlChangeSubject = new BehaviorSubject<any>(null);
  public productUrlChange$ = this.productUrlChangeSubject
    .asObservable()
    .pipe(distinctUntilChanged());

  constructor(private api: ApiService) {}

  productUrlChange(newData: any): void {
    this.productUrlChangeSubject.next(newData);
  }
  getProductDetail(params): Observable<any> {
    return this.api
      .get('api/market/app/store/' + params.storeId + '/product/' + params.slug)
      .pipe(map((data) => data));
  }
  addToFavorites(params): Observable<any> {
    return this.api.post(Urls.ADD_TO_FAVORITES, params).pipe(map((data) => data));
  }
}
