.slide {
  opacity: 0;

  &.active {
    opacity: 1;
    transition: opacity 4s ease;
  }
}
.translate-x-full-one-slider {
  --tw-translate-x: 0% !important;
}
.full-screen-image {
  width: 100%;              /* Make the image span the full width of the screen */
  height: 600px;            /* Fix the height to 600px */
  background-size: cover;   /* Ensure the image covers the entire div */
  background-position: center;
  background-repeat: no-repeat;
}
.placeholder {
  background-color: #f3f3f3; /* Placeholder background */
}