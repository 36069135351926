.scroll-transition {
  transition: scroll 0.5s ease-in-out;
}

#frameContainer {
  position: relative;
}

.next-button-container {
  position: absolute;
  top: 50%;
  right: 16px;
  transform: translateY(-50%);
  z-index: 1;
}

.arrow-left {
  position: sticky;
  top: 0;
  left: 30px;
}
.arrow-right {
  position: sticky;
  top: 117px;
  right: 35px;
  left: 0px;
}
