import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { User } from '@app/core/models/user.model';
import { AuthService } from '@app/core/services/auth.service';
import { environment } from '@environments/environment';
import {
  StripeCardElementOptions,
  StripeElementsOptions,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { injectStripe, StripeCardNumberComponent, StripeCardGroupDirective, StripeCardExpiryComponent, StripeCardCvcComponent } from 'ngx-stripe';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-add-payment-card',
    templateUrl: './add-payment-card.component.html',
    styleUrl: './add-payment-card.component.scss',
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        StripeCardGroupDirective,
        StripeCardNumberComponent,
        StripeCardExpiryComponent,
        StripeCardCvcComponent,
    ]
})
export class AddPaymentCardComponent implements OnInit {
  @ViewChild(StripeCardNumberComponent) cardNumber: StripeCardNumberComponent;
  @Input() paymentCard;
  @Output() closeDrawer = new EventEmitter<boolean>();
  @Output() onSubmit = new EventEmitter<any>();
  @Output() onDeleteAccount = new EventEmitter<boolean>();
  @ViewChild('ccNumber') ccNumberField: ElementRef;
  userPaymentCardGroup: FormGroup;
  isSubmitted = false;
  user: User;
  incomplete_number = false;
  incomplete_expiry = false;
  incomplete_cvc = false;
  errorMessage = '';

  stripe = injectStripe(environment.eddressAppConfig.stripePublicKey);
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#6B7280',
        },
        textShadow: 'normal',
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    // clientSecret: '',
  };
  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false,
    },
  };

  constructor(private formBuilder: FormBuilder, private authService: AuthService) {
    this.authService.currentUser.subscribe((data) => {
      this.user = data;
    });
  }
  ngOnInit(): void {
    this._initUserPaymentCard();
  }
  _initUserPaymentCard() {
    this.userPaymentCardGroup = this.formBuilder.group({
      nameOnCard: ['', [Validators.required]],
      // creditCard: ['', [CreditCardValidators.validateCCNumber]],
      // expirationDate: ['', [CreditCardValidators.validateExpDate]],
      // cvc: [
      //   '',
      //   [Validators.required, Validators.minLength(3), Validators.maxLength(4)],
      // ],
    });
  }
  changeCardNumber(event) {
    if (event.elementType === 'cardNumber') {
      if (event.complete) {
        this.incomplete_number = false;
      }
    } else if (event.elementType === 'cardExpiry') {
      if (event.complete) {
        this.incomplete_expiry = false;
      }
    } else if (event.elementType === 'cardCvc') {
      if (event.complete) {
        this.incomplete_cvc = false;
      }
    }
  }
  onSubmitFn() {
    this.isSubmitted = true;
    this.stripe.createToken(this.cardNumber.element).subscribe((result) => {
      if (result.token) {
        if (this.userPaymentCardGroup.valid) {
          const val = this.userPaymentCardGroup.value;
          const responseObj = {
            token: result.token.id,
            nameOnCard: val.nameOnCard,
            cardNumber: result.token.card.last4,
            expiryMonth: result.token.card.exp_month,
            expiryYear: result.token.card.exp_year,
          };
          this.onSubmit.emit(responseObj);
          this.close();
        }
      } else if (result.error) {
        this.errorMessage = result.error.message;
        if (result.error.code === 'incomplete_number') {
          this.incomplete_number = true;
          this.incomplete_expiry = true;
          this.incomplete_cvc = true;
        } else if (result.error.code === 'incomplete_expiry') {
          this.incomplete_expiry = true;
          this.incomplete_cvc = true;
        } else if (result.error.code === 'incomplete_cvc') {
          this.incomplete_cvc = true;
        }
      }
    });
  }
  close() {
    this.closeDrawer.emit(false);
  }
  deletAccount() {
    this.onDeleteAccount.emit();
  }
  get userAccountForm() {
    return this.userPaymentCardGroup.controls;
  }
}
