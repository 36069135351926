// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  eddressAppConfig: {
    apiBaseUrl: 'https://prod-api.eddress.co/',
    favIcon: 'assets/images/companies-favIcons/afc-favicon.png',
    companyTitle: 'Arabian Food Corporation',
    homePageTitle: 'Supplier, Distributor & Wholesaler KSA',
    appName: 'afc',
    countryCode: '+966',
    operationUid: 'arabianfoodcorp',
    pushToken:
      'BA9MuIuuQGj4aX6PUt8DPp6lY1DuuHUWqPE4jR7cgC5Ooc-5-HUotVx0E3ftQHOqKUsBqojCyuTfDSF_PZxPYBI',
    pushTokenProjectId: 'afc-gourmet-8cfeb',
    stripePublicKey:
      'pk_test_51LojQzAioBvl7Vd8Mgx7GgYMqdt2x0sE7O2M5QrSCjXROn7NnSvSLZzLlx4FOEEX9smd0nGlAhlELo3cyfrpLcFZ00rbJZS8hG',
    appUrls: {
      ios: 'https://apps.apple.com/us/app/afc-gourmet/id1502899378',
      android: 'https://play.google.com/store/apps/details?id=com.arabianfoodcorp&hl=en&gl=US',
    },
    paymentGateway: 'STRIPE',
  },
  firebaseConfig: {
    apiKey: 'AIzaSyDQut2Vasxm_3AJATppFF_o1pV1ZqTu4zc',
    authDomain: 'afc-gourmet-8cfeb.firebaseapp.com',
    databaseURL: 'https://afc-gourmet-8cfeb.firebaseio.com',
    projectId: 'afc-gourmet-8cfeb',
    storageBucket: 'afc-gourmet-8cfeb.appspot.com',
    messagingSenderId: '65240177446',
    appId: '1:65240177446:web:1579fa1511c8f9682dffe7',
    measurementId: 'G-7P0C7K38SY',
  },
  recaptcha: {
    siteKey: '6LfmX00qAAAAAPzm8ifzr7S_XsY090ESIIdRHcgZ',
  },
};
