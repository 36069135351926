export enum PopupTitle {
	TITLE = 'Credit Limit',
	DESCRIPTION = "You don't have enough credits to perform this action. Please reach out to your service provider.",
	ENABLE_TITLE = 'Project is not enabled',
	ENABLE_DESCRIPTION = 'Please reach out to your service provider.'
}

export enum WarehouseType {
	STATIC = 'STATIC',
	DYNAMIC = 'DYNAMIC'
}
export enum PromocodeApplication {
	SUBTOTAL,
	DELIVERY,
	TOTAL
}
export enum DiscountType {
	PERCENTAGE = 'PERCENTAGE',
	AMOUNT = 'AMOUNT'
}
export enum PromoCodeType {
	DISCOUNT = 'DISCOUNT',
	REFERRAL = 'REFERRAL',
	WALLET = 'WALLET'
}
export interface OrderData {
	orderStateLabel: string;
  statusLabel: string;
  activeTaskType: string;
	orderUid: string;
	tenantUid: string;
	scheduledOnLabel: string;
	eta: string;
	id: string;
  uid: string;
	idTeam: string;
	orderUrl: string;
	sequence: string;
	dailySequence: string;
	tenant?: Tenant;
	customer: Customer;
	legacyOrderNumber?: any;
	chequeNo?: any;
	description?: any;
	workflowData?: any;
	dispatch: Dispatch;
	orderDetails?: MarketOrderDetails;
	outsourceData?: any;
	createdOn: number;
	paymentData?: PaymentData;
	orderType: string;
	orderStatus: string;
	stats?: any;
	merchant: Merchant;
	notifications?: any;
	orderState?: any;
	orderPhase?: any;
	workflowState?: any;
	timezone?: Timezone;
	packageLocation?: PackageLocation;
	baseLocation?: PackageLocation;
	zoneData?: Zone;
	assignedOn: number;
	dispatchedOn: number;
	completedOn: number;
	completedTime: number;
	store?: Store;
	promiseDate: Date;
  promiseDateLabel: string;
	promiseTimeElapse: string;
	readyOn: number;
	cancellationReason: CancellationData;
	travelDistance: number;
	currentStateElapse: number;
	currentStateCreatedOn: number;
	currentStateCreatedOnLabel: string;
	createdOnElapsedLabel: string;
	failedOn: number;
	canceledOn: number;
	currentStateCompletedOn: number;
	feedback: FeedbackData;
	customerInstructions: string;
	deliveryNotes: string;
	incidentDetails: IncidentData;
	travelDistanceMins: number;
	callType: string;
  workerUid: string;
  pickupLat: string;
  pickupLon: string;
  deliveryLat: string;
  deliveryLon: string;
  balance: number;
  currency: string;
  total: number;
  lastUpdated: boolean;
  hasDelivery: boolean;
  totalOrders: number
}

export interface RiderData {
	id: string;
	name: string;
	phoneNumber: string;
	uid: string;
	coord: Coordinates;
	status: string;
	orderDailySequenceList: [];
	riderDailySequence: string;
}
export interface Tenant {
	uid: string;
	name: string;
	phone: string;
	email?: any;
}

export interface Customer {
	firstOrder: boolean;
	userId: string;
	uid: string;
	name: string;
	phone?: any;
	email: string;
}

export interface Project {
	id: string;
	name: string;
	status: string;
}

export interface Country {
	name: string;
	iso: string;
	countryCode: number;
	timezone?: any;
}
export interface Coordinates {
	lat: number;
	lon: number;
}

export interface Pickup {
	person: string;
	phoneNumber: string;
	notes: string;
	eddressCode?: any;
}

export interface DropOff {
	person: string;
	phoneNumber: string;
	notes: string;
	eddressCode?: any;
}

export interface Currency {
	name: string;
	iso: string;
	exchangeRate: number;
	symbol: string;
	hideDecimals: boolean;
}

export interface CashCollection {
	price: number;
	currency: Currency;
}

export interface PackageDetails {
	numberOfItems: number;
	packageType?: any;
	weight?: number;
	volume?: any;
}

export interface ServiceType {
	tripType: string;
	name?: any;
}

export interface Charges {
	deliveryPrice: number;
}

export interface OrderDetails {
	cashCollection: CashCollection;
	packageDetails: PackageDetails;
	requiredProofOfDelivery?: any;
	serviceType: ServiceType;
	charges: Charges;
	accountingNote?: any;
	comments?: any;
	cancellationReason?: any;
	weight?: number;
}

export interface MarketOrderDetails {
	items: ItemData[];
	charges: Charges;
	pickData: MerchantPickData;
	feedback: FeedbackData;
  missingItems: ItemData[];
}

export interface MerchantPickData {
	missingItems?: any;
	scannedItems?: any;
	scannedBags?: [];
	pickTime?: number;
	pickerName?: string;
	pickerUid?: string;
}
export interface OrderDashboardData {
	completedOrdersCount: number;
	delayedOrdersCount: number;
	deliveringOrdersCount: number;
	newOrdersCount: number;
	processingOrdersCount: number;
	readyOrdersCount: number;
}

export interface IncomingOrderDashboardData {
  completedOrdersCount: number;
  delayedOrdersCount: number;
  deliveringOrdersCount: number;
  newOrdersCount: number;
  processingOrdersCount: number;
  readyOrdersCount: number;
}
export interface ItemData {
	id: string;
	isSelected: boolean;
	uid: string;
	legacyId: string;
	name: string;
	labelName: string;
	description: string;
	sku: string;
	price: number;
	quantity: number;
	unitQuantity: number;
	unit: string;
	category: string;
	image: string;
	promotion: string;
	markAsDelete: boolean;
	isVariablePrice: boolean;
	weight: number;
	qtyPerUnit: number;
	newItemPrice: number;
  compensatedQuantity: number;
  missingQty: number;
  imageUrl: string;
  customizationJson: string;
  customizationItems: [];
}
export interface Dispatch {
	project: Project;
	company?: string;
	pickup: Pickup;
	dropOff: DropOff;
	tasks: Task[];
	orderDetails: OrderDetails;
	feedback?: any;
	codDriverReceipt?: any;
	scheduledOn: number;
	notes?: any;
	vehicleType: string;
	failedInfo: FailedData;
	activeWorker?: Worker;
	courier: Courier;
}

export interface PaymentData {
	paymentMethod: string;
	creditCard?: CreditCard;
	status?: any;
	transactions?: any;
}

export interface Merchant {
	uid: string;
	name: string;
	phone?: any;
	email: string;
	type?: any;
	codReceipt?: any;
	invoice?: any;
}

export interface Timezone {
	countryCode: string;
	coordinates: string;
	timeZone?: string;
	uTCoffset: string;
	uTCDSToffset?: string;
}

export interface OrderDataDto {
	orderHistory: any;
	taskHistory: any;
	orderData: OrderData;
}

export interface PackageLocation {
	locationId: string;
	warehouseName: string;
	warehouseType: string;
	warehouseId: string;
	workerUid: string;
	workerName: string;
}

export interface Worker {
	name: string;
	phoneNumber: string;
	uid: string;
	id: string;
}

export interface Task {
	uid: string;
	tripType: string;
	status: string;
	priority?: number;
	worker?: Worker;
	comments?: string;
	signatureUrl?: string;
	photoUrl?: string;
	photoOneUrl?: string;
}

export interface Zone {
	zoneId: string;
	zoneName: string;
	label: string;
	id: string;
}

export interface FailedData {
	failedReason: string;
	failedReturnable: boolean;
	internalFailed: boolean;
	failedOn: number;
}

export interface Warehouse {
	warehouseType: WarehouseType;
	id: string;
	idTenant: string;
	thirdPartyUid: string;
	name: string;
	isDeleted: boolean;
	workerUid: string;
	workerName: string;
	zones: Zone[];
}

export interface LegalDocumentDto {
	id: string;
	idAgent: string;
	name: string;
	expiryDate: any;
	image: string;
	isUploadNew: boolean;
	isDeleted: boolean;
}

export interface VehicleDto {
	id: string;
	uid: string;
	description: string;
	color: string;
	licensePlate: string;
	isActive: boolean;
	length: number;
	width: number;
	height: number;
	weight: number;
	cashLimit: number;
	vehicleType: string;
}
export interface Store {
	id: string;
	name: string;
	operationUid: string;
	phone: string;
	storeType: string;
	tags: [];
	uid: string;
	warehouseId: string;
}
export interface Charges {
	amountDue: number;
	commission: number;
	coupon: Coupon;
	deliveryCharge: number;
	deliveryVat: number;
	itemsVat: number;
	subtotal: number;
	surcharge: number;
	tips: number;
	total: number;
	vat: number;
	walletAmount: number;
}
export interface CancellationData {
	name: string;
	label: string;
}
export interface FeedbackData {
	rating: number;
	comments: string;
	predefinedFeedbackList: [];
	productRatings: [];
	createdOn: number;
}
export interface Courier {
	uid: string;
}
export interface Coupon {
	codeId: string;
	code: string;
	codeType: PromoCodeType;
	description: string;
	discountAmount: number;
	discountValue: number;
	appliedOn: PromocodeApplication;
	discountType: DiscountType;
}
export interface IncidentData {
	hasIncident: boolean;
	incidents: TicketData;
}
export interface TicketData {
	id: string;
	category: string;
	subCategory: string;
	compensateAmount: number;
}
export interface CreditCard {
	paymentGateway: string;
	expiry: number;
	id: string;
	name: string;
}
