.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

section .section-title {
    font-size: 36px;
    font-weight: 700;
    margin-bottom: 50px;
    color: #404040;
}
section .section-para {
    font-size: 18px;
    font-weight: 500;
    color: #404040;
    line-height: 30px;
    max-width: 860px;
    margin: 0 auto 80px;
}
section .section-title::after {
    content: '';
    width: 90px;
    height: 4px;
    border-radius: 5px;
    display: block;
    margin: 10px auto;
    background-color: #404040;
}
.contact-wrap .default-form-wrap {
    max-width: 980px;
    margin: 0 auto;
    text-align: center;
    // padding: 70px;
    background-color: #fff;
    box-shadow: 0 3px 50px 0 rgba(0, 0, 0, 0.03);
}
.contact-wrap .cw-top {
    // background: #0b689a;
    // background: -moz-linear-gradient(45deg, #0b689a 0%, #4baadc 50%, #0b689a 100%);
    // background: -webkit-linear-gradient(45deg, #0b689a 0%, #4baadc 50%, #0b689a 100%);
    // background: linear-gradient(45deg, #0b689a 0%, #4baadc 50%, #0b689a 100%);
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0b689a', endColorstr='#0b689a',GradientType=1 );
    padding: 60px 0;
    color: #ffffff;
}
.contact-wrap .cw-bottom {
    background-color: #0B689A;
    padding: 70px 0;
}
.contact-wrap .cw-top .card-wrap {
    position: relative;
    padding-left: 50px;
}
.contact-wrap .cw-top .card-wrap p a:hover {
    color: #ffffff;
}
.contact-wrap .cw-top .section-title::after {
    background-color: #ffffff;
}