import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { AddressesService } from '@app/user-information/addresses/services/addresses.service';
import { FormsModule } from '@angular/forms';
import { NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'app-address-list',
    templateUrl: './address-list.component.html',
    styleUrl: './address-list.component.scss',
    imports: [
        NgFor,
        FormsModule,
        NgIf,
    ]
})
export class AddressListComponent implements OnInit {
  @Input() eddressCode;
  @Output() closeDrawer = new EventEmitter<boolean>();
  @Output() confirmLocation = new EventEmitter<boolean>();
  @Output() newLocation = new EventEmitter<boolean>();
  headerTitle = '';
  selectedAddress = '';
  addressList = [];
  user;
  constructor(private addressesService: AddressesService, private authService: AuthService) {
    this.authService.currentUser.subscribe((data) => {
      if (Object.keys(data).length !== 0) {
        this.user = data;
      }
    });
  }
  ngOnInit(): void {
    this.selectedAddress = this.eddressCode;
    this.getAddresses();
  }
  onChangeAddress() {
    const address = this.addressList.find((a) => a.eddressCode === this.selectedAddress);
    this.headerTitle = address?.name;
  }
  close() {
    this.closeDrawer.emit(false);
  }
  newLocationFn() {
    this.newLocation.emit();
  }
  getAddresses() {
    if(this.user) {
      this.addressesService.getUserAddresses(this.user.uid).subscribe((result) => {
        this.addressList = result;
        this.onChangeAddress();
      });
    }
  }
  onConfirmAddress() {
    const selectedAddress = this.addressList.find((a) => a.eddressCode === this.selectedAddress);
    this.confirmLocation.emit(selectedAddress);
    this.close();
  }
}
