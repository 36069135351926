import { Injectable } from '@angular/core';
import { Coupon } from '@app/core/models/coupon.model';
import { ApiService } from '@app/core/services/api.service';
import { Urls } from '@app/core/util/urls';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CouponService {
  constructor(private api: ApiService) {}
  getMyCoupons(params): Observable<Coupon[]> {
    return this.api.post(Urls.GET_PROMO_CODES, params).pipe(map((data) => data));
  }
  addCreditCard(params): Observable<any> {
    return this.api.post(Urls.ADD_CREDIT_CARD, params).pipe(map((data) => data));
  }
}
