import {
  AfterViewInit,
  Component,
  ElementRef,
  inject,
  OnInit,
  signal,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { CheckoutService } from './services/checkout.service';
import { LoaderService } from '@app/core/services/loader.service';
import { GenericService } from '@app/core/services/generics.service';
import { AddressesService } from '@app/user-information/addresses/services/addresses.service';
import { User } from '@app/core/models/user.model';
import { AuthService } from '@app/core/services/auth.service';
import { environment } from '@environments/environment';
import { NotificationService } from '@app/core/services/notification.service';
import { Router } from '@angular/router';
import { LocalStorageService } from '@app/core/services/localstorage.service';

import {
  injectStripe,
  StripeCardComponent,
  StripeCardNumberComponent,
  StripePaymentElementComponent,
} from 'ngx-stripe';
import {
  StripeElementsOptions,
  Appearance,
  StripeCardElementOptions,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { PaymentsService } from '@app/user-information/payments/services/payments.service';
import { ConfirmationPopupComponent } from '../shared/components/confirmation-popup/confirmation-popup.component';
import { ModalComponent } from '../shared/components/modal/modal.component';
import { DeliveryTimeScheduleComponent } from '../shared/components/delivery-time-schedule/delivery-time-schedule.component';
import { AddCardknoxCardComponent } from '../user-information/payments/components/add-cardknox-card/add-cardknox-card.component';
import { AddPaymentCardComponent } from '../user-information/payments/components/add-payment-card/add-payment-card.component';
import { MissingItemsComponent } from '../shared/components/missing-items/missing-items.component';
import { AddLocationComponent } from '../shared/components/add-location/add-location.component';
import { AddressListComponent } from '../shared/components/address-list/address-list.component';
import { DrawerComponent } from '../shared/drawer/drawer.component';
import { NgIf, NgFor, DecimalPipe } from '@angular/common';
import { AddAdyenCardComponent } from '@app/user-information/payments/components/add-adyen-card/add-adyen-card.component';
import { Store } from '@ngrx/store';
import { AppState } from '@app/app.state';
import { selectAlertssState } from '@app/store/selectors/alerts.selector';
import { ShowAlertService } from '@app/shared/services/showalerts.service';
import { ShowAlertComponent } from '@app/shared/components/show-alert/show-alert.component';

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrl: './checkout.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        NgIf,
        NgFor,
        DrawerComponent,
        AddressListComponent,
        AddLocationComponent,
        MissingItemsComponent,
        AddPaymentCardComponent,
        AddCardknoxCardComponent,
        DeliveryTimeScheduleComponent,
        ModalComponent,
        ConfirmationPopupComponent,
        DecimalPipe,
        AddAdyenCardComponent,
        ShowAlertComponent,
    ]
})
export class CheckoutComponent implements OnInit, AfterViewInit {
  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;
  // @ViewChild(StripeElementsDirective) elements!: StripeElementsDirective;

  @ViewChild(StripeCardComponent) card: StripeCardComponent;
  @ViewChild(StripeCardNumberComponent) cardNumber: StripeCardNumberComponent;

  @ViewChild('ccNumber') ccNumberField: ElementRef;
  checkoutFormGroup: FormGroup;
  schedule = [];
  termsAndConditionsUrl;

  private readonly fb = inject(UntypedFormBuilder);

  paymentGateway = environment.eddressAppConfig.paymentGateway;
  stripe = injectStripe(environment.eddressAppConfig.stripePublicKey);
  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: '#31325F',
        fontWeight: '300',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSize: '16px',
        '::placeholder': {
          color: '#6B7280',
        },
        textShadow: 'normal',
      },
    },
  };
  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    // clientSecret: '',
  };

  stripeTest: FormGroup;

  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'tabs',
      defaultCollapsed: false,
      radios: false,
      spacedAccordionItems: false,
    },
  };
  appearance: Appearance = {
    theme: 'stripe',
    labels: 'floating',
    variables: {
      colorPrimary: '#673ab7',
    },
  };

  paying = signal(false);

  isAddPromoCode = false;
  promoCodeApplied = false;

  isModalOpen: boolean = false;
  isConfirmationOpen: boolean = false;
  isAlertModalOpen: boolean = false;
  isShowAlertOpen: boolean = false;
  confirmationTitle = '';

  promoCode = '';
  validPromoCode;
  isDrawerOpen: boolean = false;
  isBackdropDrawerOpen: boolean = false;
  isAddressesOpen: boolean = false;
  isLocationOpen: boolean = false;
  isMissingItemDrawerOpen: boolean = false;
  isPaymentDrawerOpen: boolean = false;
  isDeliveryTimeDrawerOpen: boolean = false;
  isSubmitted = false;
  orderDetail;
  selectedStore;
  checkOutSummery;
  cartItems = [];
  missingItems = [];
  paymentCards = [];
  predefinedNotes = [];
  configuration;
  userData: User;
  deliveryAddress;
  isTermConditions = false;
  hasDelivery = true;
  isPaymentGateWayAvailable = true;
  paymentOptions = [];
  appName = environment.eddressAppConfig.appName;

  constructor(
    private formBuilder: FormBuilder,
    private checkoutService: CheckoutService,
    private loaderService: LoaderService,
    private genericService: GenericService,
    private addressesService: AddressesService,
    private authService: AuthService,
    private notification: NotificationService,
    public router: Router,
    private localStorage: LocalStorageService,
    private paymentsService: PaymentsService,
    private store: Store<AppState>,
    private alertService: ShowAlertService
  ) {
    this.authService.selectedStore.subscribe((data) => {
      if (data) {
        this.selectedStore = data;
        this.paymentOptions = this.selectedStore.paymentOptions;
        this.predefinedNotes = this.selectedStore?.predefinedNotes?.map((n) => {
          const note = {
            value: n,
            isSelected: false,
          };
          return note;
        });
      }
    });
    this.genericService.getConfigurations().subscribe((result) => {
      this.configuration = result;
    });
    this.authService.currentUser.subscribe((data) => {
      this.userData = data;
    });
    this.authService.deliveryAddress.subscribe((data) => {
      if (data) {
        this.deliveryAddress = data;
      }
    });
    this.genericService.updateCartItem$.subscribe((isCartUpdated) => {
      if (isCartUpdated) this.getCartDetail();
    });
  }

  ngOnInit(): void {
    // this.genericService.setPageTitleAndDescription('checkout', '');
    this._initCheckoutForm();
    this.getCartDetail();
    this.getPaymentCards();
    this.getSchedule();
    // this.intializePaymentSession();
    this.stripeTest = this.fb.group({
      name: ['Angular v10', [Validators.required]],
      amount: [1001, [Validators.required, Validators.pattern(/d+/)]],
    });
    this.termsAndConditionsUrl = this.localStorage.getItem('termsAndConditionsUrl');
  }
  ngAfterViewInit(): void {
    this.store.select(selectAlertssState).subscribe((result) => {
      const alerts = result.alerts;
      alerts.forEach((alert) => {
        if (alert.displayType === 'CHECKOUT') {
          this.showAlertOnce(alert);
        }
      });
    });
  }
  ngAfterContentInit() {
    const backdrop = document.getElementById('adyen-backrop');
    backdrop.style.display = 'none';
    // backdrop.style.visibility = 'hidden';
  }
  _initCheckoutForm() {
    this.checkoutFormGroup = this.formBuilder.group({
      addressType: ['delivery', [Validators.required]],
      paymentMethod: [
        this.paymentOptions.length === 1 ? this.paymentOptions[0].uid : 'cash',
        Validators.required,
      ],
      creditCardUid: [''],
      notes: [''],
      deliveryDate: [this.selectedStore?.defaultEtaText || ''],
      // creditCard: [''],
      // expirationDate: [''],
      // cvc: [''],
    });
  }

  onChangeAddressType(value) {
    if (value === 'delivery') {
      this.hasDelivery = true;
    } else {
      this.hasDelivery = false;
    }
    this.calculateBasket();
  }
  onChangePaymentType(value) {
    if (value === 'credit') {
      this.setCreditCardValidators(true);
    } else {
      this.setCreditCardValidators(false);
    }
  }
  applyPromoCode() {
    const params = {
      hasDelivery: this.hasDelivery,
      lat: this.deliveryAddress?.coordinates?.lat,
      lon: this.deliveryAddress?.coordinates?.lon,
      promo: this.promoCode,
    };
    this.checkoutService.validatePromoCode(params).subscribe((result) => {
      if (result.message === 'valid') {
        this.validPromoCode = result;
        this.localStorage.setItem('validPromoCode', JSON.stringify(result));
        this.notification.successNotification(result.description);
        this.promoCodeApplied = true;
        this.isAddPromoCode = false;
        this.calculateBasket();
      } else {
        this.notification.errorNotification(result.description);
      }
    });
  }
  addPromoCode() {
    this.promoCode = '';
    this.isAddPromoCode = !this.isAddPromoCode;
  }
  removePromoCodeConfirmation() {
    this.confirmationTitle = 'Are you sure you want to remove promocode';
    this.openModal();
  }
  removePromoCode() {
    this.closeConfrimationPopUp(true);
    this.localStorage.removeItem('validPromoCode');
    this.validPromoCode = null;
    this.promoCodeApplied = false;
    this.isAddPromoCode = false;
    this.calculateBasket();
  }
  reset() {
    this.isDrawerOpen = false;
    this.isBackdropDrawerOpen = false;
    this.calculateBasket();
  }
  closeAddresses($event) {
    this.isDrawerOpen = false;
    this.isBackdropDrawerOpen = false;
    this.isLocationOpen = $event;
  }
  closeLocation($event) {
    this.isDrawerOpen = false;
    this.isBackdropDrawerOpen = false;
    this.isLocationOpen = $event;
  }
  closeMissingDrawer($event) {
    this.isDrawerOpen = false;
    this.isMissingItemDrawerOpen = $event;
    this.calculateBasket();
  }
  closePaymentCardDrawer($event) {
    this.isDrawerOpen = false;
    this.isBackdropDrawerOpen = false;
    this.isPaymentDrawerOpen = $event;
  }
  closeDeliveryDrawer($event) {
    this.isDrawerOpen = false;
    this.isBackdropDrawerOpen = false;
    this.isDeliveryTimeDrawerOpen = $event;
  }
  openDrawer(type) {
    this.isDrawerOpen = true;
    this.isBackdropDrawerOpen = true;
    this.isLocationOpen = false;
    this.isAddressesOpen = false;
    this.isMissingItemDrawerOpen = false;
    this.isPaymentDrawerOpen = false;
    this.isDeliveryTimeDrawerOpen = false;

    if (type === 'location') {
      this.isLocationOpen = true;
    } else if (type === 'addresses') {
      this.isAddressesOpen = true;
    } else if (type === 'missingItems') {
      this.isMissingItemDrawerOpen = true;
    } else if (type === 'paymentCard') {
      this.isPaymentDrawerOpen = true;
    } else if (type === 'deliveryTime') {
      this.isDeliveryTimeDrawerOpen = true;
    }
  }
  getDeliveryAddress(deliveryAddress) {
    this.deliveryAddress = deliveryAddress;
    this.calculateBasket();
  }
  saveLocation(location) {
    const params = {
      address: {
        locality: location.locality,
        state: '',
        uid: this.userData.uid,
        version: '3.1.9',
        street: location.street,
        building: location.building,
        locale: 'es',
        os: 'iOS',
        type: '',
        appName: environment.eddressAppConfig.appName,
        coordinates: location.coordinates,
        city: '',
        phoneCountryCode: '0',
        idUser: this.userData.idUser,
        phoneCountryId: '0',
        notes: '',
        name: location.name,
        creationLat: location.coordinates.lat,
        creationLon: location.coordinates?.lon,
      },
    };
    this.loaderService.open();
    this.addressesService.saveUserAddress(params).subscribe(
      (result) => {
        this.loaderService.close();
        this.notification.successNotification('Address saved successfully');
        this.closeLocation(true);
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }
  getPaymentCards() {
    this.paymentsService.getPaymentCards({}).subscribe(
      (result) => {
        this.paymentCards = result;
      },
      (err) => {}
    );
  }
  onCardChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    if (selectElement.value === 'addCard') {
      this.openDrawer('paymentCard');
      this.checkoutFormGroup.get('creditCardUid').setValue('');
    }
  }
  getCartDetail() {
    this.genericService.getBasket().subscribe((result) => {
      this.cartItems = result?.data?.items;
      this.validPromoCode = JSON.parse(this.localStorage.getItem('validPromoCode'));
      if (!this.validPromoCode) {
        this.checkPermanentPromoCode();
      } else {
        this.calculateBasket();
        this.promoCodeApplied = true;
        this.isAddPromoCode = false;
      }
    });
  }

  checkPermanentPromoCode() {
    const params = {
      eddressCode: this.deliveryAddress?.eddressCode,
      items: this.cartItems?.map((item) => item.productId),
      storeId: this.selectedStore?.id,
    };
    this.checkoutService.checkPermanentPromo(params).subscribe((result) => {
      if (result?.message === 'valid') {
        this.validPromoCode = result;
        this.localStorage.setItem('validPromoCode', JSON.stringify(result));
        this.promoCodeApplied = true;
        this.isAddPromoCode = false;
      }
      this.calculateBasket();
    });
  }
  getSchedule() {
    const params = {
      coord: { lat: this.selectedStore?.lat, lon: this.selectedStore?.lon },
      forceFetchStoreSchedules: false,
      hideTodaySchedule: environment.eddressAppConfig.appName === 'afc' ? true : false,
      isPickupFromStore: false,
      showCalendarView: environment.eddressAppConfig.appName === 'afc' ? true : false,
    };
    this.checkoutService.getSchedule(this.selectedStore?.id, params).subscribe((result) => {
      this.schedule = result.data;
    });
  }

  intializePaymentSession() {
    this.checkoutService.paymentSession(environment.eddressAppConfig, {}).subscribe(
      (result) => {
        // 'pk_test_51LojQzAioBvl7Vd8Mgx7GgYMqdt2x0sE7O2M5QrSCjXROn7NnSvSLZzLlx4FOEEX9smd0nGlAhlELo3cyfrpLcFZ00rbJZS8hG'
        // 'seti_1OzFtFAioBvl7Vd83xiJHF5l_secret_Potg1fzZvS3hFz881VOX2SwaBUx8nRa';
        this.elementsOptions.clientSecret = result?.data?.clientSecret;

        this.isPaymentGateWayAvailable = true;
      },
      (error) => {
        this.isPaymentGateWayAvailable = false;
      }
    );
  }

  calculateBasket() {
    if (this.cartItems?.length > 0) {
      const backetitems = this.cartItems?.map((item) => {
        const product = item.product;
        const obj = {
          // collectionId: '6478b7cb790d915f07fc16bd',
          collectionName: product.collection,
          customizationItems: product.customizationItems,
          // groupId: '6478b817790d915f07fc16bf',
          groupLabel: product.group,
          hasPriceRange: product.hasPriceRange,
          id: product.id,
          itemsOrdered: item.quantity,
          price: product.price,
          vatFree: product.vatFree,
          vatPercent: product.vatPercent,
        };
        return obj;
      });
      const params = {
        dynamicDeliveryParam: {
          deliveryLat: this.deliveryAddress?.coordinates?.lat,
          deliveryLon: this.deliveryAddress?.coordinates?.lon,
        },
        isPayWithWallet: true,
        items: backetitems || [],
        locale: 'es',
        orderHasDelivery: this.hasDelivery,
        serviceProviderId: this.selectedStore?.id,
        tip: 0.0,
      };
      if (!this.validPromoCode) {
        this.validPromoCode = JSON.parse(this.localStorage.getItem('validPromoCode'));
        if (this.validPromoCode) {
          this.promoCodeApplied = true;
          this.isAddPromoCode = false;
        }
      }
      if (this.validPromoCode) {
        params['promoCodeUid'] = this.validPromoCode.uid;
        params['reCalculateBasket'] = true;
        params['promoCodeParam'] = {
          lat: this.deliveryAddress?.coordinates?.lat,
          lon: this.deliveryAddress?.coordinates?.lon,
          promo: this.validPromoCode?.promoCode,
          serviceUid: this.selectedStore?.id,
          hasDelivery: this.hasDelivery,
          forceApplyPromoCode: false,
        };
      }
      this.loaderService.open();
      this.genericService.calculateBasket(params).subscribe(
        (result) => {
          this.loaderService.close();
          if (result.missingItems && result.missingItems.length > 0) {
            this.missingItems = result.missingItems;
            const productIds = result.missingItems.map((i) => i.id);
            this.cartItems = this.cartItems.filter((c) => !productIds.includes(c.productId));
            this.openDrawer('missingItems');
            this.missingItems.forEach((item) => {
              const itemObj = { id: item.id, quantity: item.quantityOnHand };
              this.manageBasket(itemObj);
            });
          }
          this.checkOutSummery = result;
          if (this.checkOutSummery.discount === 0) {
            this.promoCodeApplied = false;
          }
        },
        (err) => {
          this.loaderService.close();
        }
      );
    } else {
      // this.router.navigate(['/']);
    }
  }
  manageBasket(item) {
    const params = {
      storeId: this.selectedStore?.id,
      productId: item.id,
      quantity: item.quantity,
    };
    this.genericService.manageBasket(params).subscribe((result) => {});
  }
  placeOrder() {
    if (!this.checkoutFormGroup.valid) {
      this.checkoutFormGroup.get('creditCardUid').markAsDirty();
      return;
    }
    const backetitems = this.cartItems.map((item) => {
      const product = item.product;
      const obj = {
        category: product.category,
        // collectionId: '623a09dfacea1d1061520468',
        collectionName: product.collection,
        customizationItems: product.customizationItems,
        // groupId: '6322c7f98dabbc6d1876d136',
        hasPriceRange: product.hasPriceRange,
        id: product.id,
        imageUrl: product.imageUrl,
        isVariablePrice: false,
        itemPrice: product.price,
        label: product.label,
        marketProductItemId: product.id,
        qtyPerUnit: 1,
        quantity: item.quantity,
        sku: product.sku,
        uidProduct: product.id,
        unit: product.unit,
        vat: product.vat,
        vatFree: product.vatFree,
      };
      return obj;
    });
    this.isSubmitted = true;
    const formValues = this.checkoutFormGroup.value;
    let params: any = {
      cashCollection: this.checkOutSummery?.amountDue
        ? this.checkOutSummery?.amountDue
        : this.checkOutSummery?.totalPrice,
      currencySymbol: this.configuration.currencySymbol,
      deductionFromWallet: this.checkOutSummery?.deductionFromWalletInServiceCurrency,
      deliveryDate: formValues.deliveryDate,
      deliveryCharge: this.checkOutSummery?.deliveryPrice,
      deliveryDistanceKm: this.checkOutSummery?.deliveryDistance,
      deliveryEddress: this.deliveryAddress?.eddressCode,
      deliveryLat: this.deliveryAddress?.coordinates?.lat,
      deliveryLon: this.deliveryAddress?.coordinates?.lon,
      deliveryVat: this.checkOutSummery?.deliveryVatAmount,
      discountAmount: this.checkOutSummery?.discount,
      hasDelivery: this.hasDelivery,
      hasPricing: true,
      isPickup: formValues.addressType === 'pickup' ? true : false,
      items: backetitems,
      itemsVatAmount: this.checkOutSummery.totalVatAmount,
      locale: 'en',
      minimumChargeFee: 0.0,
      os: 'web',
      paymentOptionUid: formValues.paymentMethod,
      promiseTime: 0,
      serviceName: this.selectedStore?.name,
      serviceUid: this.selectedStore?.slug,
      storeId: this.selectedStore?.id,
      subTotal: this.checkOutSummery.subtotalPrice,
      tips: 0.0,
      total: this.checkOutSummery.totalPrice,
      totalVatAmount: this.checkOutSummery.totalVatAmount,
      notes: formValues.notes,
    };
    if (this.validPromoCode?.promoCode) {
      params.promoCode = this.validPromoCode?.promoCode;
    }
    if (formValues.paymentMethod === 'credit') {
      const selectedCard = this.paymentCards.find((p) => p.uid === formValues.creditCardUid);
      if (selectedCard) params.creditCardParam = selectedCard;
    }
    if (this.predefinedNotes?.length > 0) {
      params.predefinedNotes = this.predefinedNotes
        .filter((n) => n.isSelected)
        .map((nv) => nv.value);
    }
    if (formValues.paymentMethod === 'adyendropin') {
      params.creditCardParam = {
        paymentGateway: 'ADYENDROPIN',
      };
      params.isCreditCardPaymentMissing = true;
    }
    // const backdrop = document.getElementById('adyen-backrop');
    // backdrop.style.display = 'inline';
    // this.openDrawer('paymentCard');
    this.loaderService.open();
    this.checkoutService.placeOrder(params).subscribe(
      (result) => {
        this.isSubmitted = false;
        this.loaderService.close();
        if (result?.message === 'valid') {
          this.orderDetail = result?.data;
          if (formValues.paymentMethod === 'adyendropin') {
            const backdrop = document.getElementById('adyen-backrop');
            backdrop.style.display = 'inline';
            this.openDrawer('paymentCard');
          } else {
            this.notification.successNotification('Order placed successfully');
            this.localStorage.removeItem('validPromoCode');
            const orderUid = result?.data?.uid;
            this.getCartDetail();
            setTimeout(() => {
              this.router.navigate(['order-detail/' + orderUid]);
            }, 1000);
          }
        } else {
          this.notification.errorNotification(result?.description);
        }
      },
      (err) => {
        this.isSubmitted = false;
        this.loaderService.close();
      }
    );
  }
  viewCart() {
    this.router.navigate(['/view-cart']);
  }
  resetModal() {
    this.isModalOpen = false;
    this.isAlertModalOpen = false;
  }
  closeConfrimationPopUp($event) {
    this.isModalOpen = false;
    this.isConfirmationOpen = $event;
  }
  openModal() {
    this.isModalOpen = true;
    this.isConfirmationOpen = true;
  }
  closeAlertPopUp($event) {
    this.isAlertModalOpen = false;
    this.isShowAlertOpen = $event;
  }
  openAlertModal() {
    this.isAlertModalOpen = true;
    this.isShowAlertOpen = true;
  }
  setCreditCardValidators(isRequired) {
    const creditCardControl = this.checkoutFormGroup.get('creditCardUid');
    // const expirationDateControl = this.checkoutFormGroup.get('expirationDate');
    // const cvcControl = this.checkoutFormGroup.get('cvc');
    if (isRequired) {
      creditCardControl.setValidators([Validators.required]);
      // expirationDateControl.setValidators([CreditCardValidators.validateExpDate]);
      // cvcControl.setValidators([
      //   Validators.required,
      //   Validators.minLength(3),
      //   Validators.maxLength(4),
      // ]);
    } else {
      creditCardControl.setValidators(null);
      // expirationDateControl.setValidators(null);
      // cvcControl.setValidators(null);
    }
    creditCardControl.updateValueAndValidity();
    creditCardControl.markAsUntouched();
    // expirationDateControl.updateValueAndValidity();
    // expirationDateControl.markAsUntouched();
    // cvcControl.updateValueAndValidity();
    // cvcControl.markAsUntouched();
  }
  pay() {
    if (this.paying() || this.checkoutFormGroup.invalid) return;
    this.paying.set(true);

    // const { name, email, address, zipcode, city } = this.checkoutFormGroup.getRawValue();

    this.stripe
      .confirmSetup({
        elements: this.paymentElement.elements,
        // confirmParams: {
        //   payment_method_data: {
        //     billing_details: {
        //       name: name as string,
        //       email: email as string,
        //       address: {
        //         line1: address as string,
        //         postal_code: zipcode as string,
        //         city: city as string,
        //       },
        //     },
        //   },
        // },
        redirect: 'if_required',
      })
      .subscribe((result) => {
        this.paying.set(false);
        if (result.error) {
          // Show error to your customer (e.g., insufficient funds)
          alert({ success: false, error: result.error.message });
        } else {
          // The payment has been processed!
          // if (result.paymentIntent.status === 'succeeded') {
          //   // Show a success message to your customer
          //   alert({ success: true });
          // }
        }
      });
  }
  createToken(): void {
    this.stripe.createToken(this.cardNumber.element).subscribe((result) => {
      if (result.token) {
        // Use the token
        this.addCreditCard(result.token.id);
      } else if (result.error) {
        // Error creating the token
        // console.log(result.error.message);
      }
    });
  }

  addCreditCard(event) {
    this.loaderService.open();
    const params = {
      cardToken: event.token,
      email: this.userData.email,
      locale: 'en',
      name: event.nameOnCard,
      paymentGateway: 'STRIPE',
      uid: this.userData.uid,
      cardNumber: event.cardNumber,
      expiryMonth: event.expiryMonth,
      expiryYear: event.expiryYear,
    };
    this.paymentsService.addCreditCard(params).subscribe((result) => {
      this.loaderService.close();
      this.notification.successNotification('Credit card added successfully');
      this.getPaymentCards();
    });
    this.resetModal();
  }
  addCardKnoxCreditCard(event) {
    const params = {
      cardToken: event.xToken,
      email: this.userData.email,
      locale: 'en',
      os: 'Web',
      name: event.xName,
      paymentGateway: 'CARDKNOX',
      uid: this.userData.uid,
      cardNumber: event.xMaskedCardNumber,
      expiryMonth: event.xExp.substring(0, 2),
      expiryYear: event.xExp.substring(2),
    };
    this.loaderService.open();
    this.paymentsService.addCreditCard(params).subscribe(
      (result) => {
        this.loaderService.close();
        this.notification.successNotification('Credit card added successfully');
        this.getPaymentCards();
      },
      (error) => {
        this.loaderService.close();
      }
    );
    this.resetModal();
  }

  onSubmitPayment(event) {
    this.notification.successNotification('Order placed successfully');
    this.localStorage.removeItem('validPromoCode');
    this.getCartDetail();
    setTimeout(() => {
      this.router.navigate(['order-detail/' + this.orderDetail.uid]);
    }, 1000);
  }
  changeDeliveryTime(event) {
    this.checkoutFormGroup.get('deliveryDate').setValue(event);
  }
  showAlertOnce(alert) {
    const alertKey = `shown_${alert.id}`;
    if (!sessionStorage.getItem(alertKey)) {
      this.openAlertModal();
      this.alertService.addAlert(alert);
      sessionStorage.setItem(alertKey, 'true');
    }
  }
  get checkoutForm() {
    return this.checkoutFormGroup.controls;
  }
}
