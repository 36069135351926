import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { NgFor, NgClass } from '@angular/common';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-user-information',
    templateUrl: './user-information.component.html',
    styleUrl: './user-information.component.scss',
    imports: [NgFor, NgClass, RouterOutlet]
})
export class UserInformationComponent {
  submenus = [
    {
      name: 'My Account',
      icon: 'assets/images/home/usercircle.svg',
      url: 'user/my-account',
      isActive: false,
    },
    {
      name: 'Orders',
      icon: 'assets/images/user/shoppingbag01.svg',
      url: 'user/orders',
      isActive: false,
    },
    {
      name: 'Favorites',
      icon: 'assets/images/user/heartrounded.svg',
      url: 'user/favorites',
      isActive: false,
    },
    {
      name: 'Addresses',
      icon: 'assets/images/user/markerpin03.svg',
      url: 'user/addresses',
      isActive: false,
    },
    {
      name: 'Payments',
      icon: 'assets/images/user/creditcardlock.svg',
      url: 'user/payments',
      isActive: false,
    },
    {
      name: 'My Wallet',
      icon: 'assets/images/user/wallet-02.svg',
      url: 'user/my-wallet',
      isActive: false,
    },
    {
      name: 'Refer a Friend',
      icon: 'assets/images/user/userplus01.svg',
      url: 'user/refer-friend',
      isActive: false,
    },
    {
      name: 'Logout',
      icon: 'assets/images/user/logout04.svg',
      url: 'logout',
      isActive: false,
    },
  ];
  constructor(public router: Router, private authService: AuthService) {
    if (environment.eddressAppConfig.paymentGateway === 'ADYENDROPIN') {
      this.submenus = this.submenus.filter((m) => m.name !== 'Payments');
    }
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const url = event.url.substring(1);
        if (url === 'user/my-wallet') {
          this.submenus.forEach((m) => {
            m.isActive = false;
            if (m.url === url) {
              m.isActive = true;
            }
          });
        }
      }
    });
  }

  onClickMenu(menu) {
    this.submenus.forEach((m) => (m.isActive = false));
    menu.isActive = true;
    if (menu.url !== 'logout') {
      this.router.navigate([menu.url]);
    } else {
      this.logout();
    }
  }

  logout() {
    this.authService.removeUserAuthentication();
    localStorage.clear();
    this.router.navigateByUrl('/');
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}
