import {
  Component,
  OnInit,
  ViewEncapsulation,
  Input,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  Renderer2,
  ElementRef,
  ViewChild,
  ViewChildren,
  QueryList,
  HostListener,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { User } from '@app/core/models/user.model';
import { AuthService } from '@app/core/services/auth.service';
import { EddressFacadeService } from '@app/core/services/eddressFacade.service';
import { GenericService } from '@app/core/services/generics.service';
import { LoaderService } from '@app/core/services/loader.service';
import { NotificationService } from '@app/core/services/notification.service';
import { CartService } from '@app/product/services/cart.service';
import { AddressesService } from '@app/user-information/addresses/services/addresses.service';
import { OrdersService } from '@app/user-information/orders/services/orders.service';
import { environment } from '@environments/environment';
import { Observable, Subscription, debounceTime } from 'rxjs';
import { AddressListComponent } from '../../shared/components/address-list/address-list.component';
import { AddLocationComponent } from '../../shared/components/add-location/add-location.component';
import { CartComponent } from './components/cart/cart.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SignupComponent } from './components/signup/signup.component';
import { LoginComponent } from './components/login/login.component';
import { DrawerComponent } from '../../shared/drawer/drawer.component';
import { NgIf, NgFor, NgClass, AsyncPipe } from '@angular/common';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import { ModalComponent } from '@app/shared/components/modal/modal.component';
import { ShowAlertComponent } from '@app/shared/components/show-alert/show-alert.component';
import { ShowAlertService } from '@app/shared/services/showalerts.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrl: './header.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [
        NgIf,
        NgFor,
        NgClass,
        RouterLink,
        FormsModule,
        ReactiveFormsModule,
        DrawerComponent,
        LoginComponent,
        SignupComponent,
        ForgotPasswordComponent,
        CartComponent,
        AddLocationComponent,
        AddressListComponent,
        AsyncPipe,
        ModalComponent,
        ShowAlertComponent,
    ]
})
export class HeaderComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() menuCollections;
  @Input() languages;
  @Input() companyLogo;
  defaultLogo;
  searchStringControl = new FormControl();
  formCtrlSub: Subscription;
  currencies = [
    {
      name: 'USD',
      value: 'usd',
    },
    {
      name: 'PKR',
      value: 'pkr',
    },
  ];
  addressList = [];
  deliveryAddress;
  isLoginOpen: boolean = false;
  isSignupOpen: boolean = false;
  isForgotPasswordOpen: boolean = false;
  isDrawerOpen: boolean = false;
  isLocationOpen: boolean = false;
  isAddressesOpen: boolean = false;
  isCartOpen: boolean = false;
  searchString = '';
  userData: User;
  isLoggedIn: Observable<boolean>;
  isHeaderModalOpen: boolean = false;
  isSignUpAlertOpen: boolean = false;
  userLogedIn = false;
  cartItemsCount = 0;
  moreMenuOpen = false;
  mobileMenuOpen = false;

  selectedLanguage = {
    name: 'English',
    value: 'English,en',
  };
  selectedCurrency = {
    name: 'USD',
    value: 'usd',
  };

  menus: any = [];
  moreMenus: any = [];
  fullMenus: any = [];
  showMoreMenu = false;

  deliveringETA;
  deliverToETA;
  moreMenuSide = 'right';
  hideAddressBadge = false;
  fillColor;
  paymentGateway = environment.eddressAppConfig.paymentGateway;

  @ViewChildren('toggleMenuButtons') toggleMenuButtons: QueryList<ElementRef>;
  @ViewChildren('toggleMoreMenuButtons') toggleMoreMenuButtons: QueryList<ElementRef>;
  @ViewChildren('toggleFullMenuButtons') toggleFullMenuButtons: QueryList<ElementRef>;
  @ViewChild('toggleMoreButton') toggleMoreButton: ElementRef;
  @ViewChild('moreMenu') moreMenu: ElementRef;
  @ViewChild('frameContainerMenus') frameContainerMenus: ElementRef;
  @ViewChild('menuContainer') menuContainer: ElementRef;
  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    // this.checkElementOverflowingHorizontally();
    this.distributeMenuItems();
  }

  constructor(
    public router: Router,
    private authService: AuthService,
    private cartService: CartService,
    private genericService: GenericService,
    private addressesService: AddressesService,
    private loaderService: LoaderService,
    private notification: NotificationService,
    public eddress: EddressFacadeService,
    private ordersService: OrdersService,
    private renderer: Renderer2,
    private alertService: ShowAlertService
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      this.menus.forEach((m) => (m.isOpen = false));
      this.moreMenus.forEach((m) => (m.isOpen = false));
      this.fullMenus.forEach((m) => (m.isOpen = false));
      this.toggleMenuButtons.forEach((toggleButton: ElementRef) => {
        if (toggleButton.nativeElement.contains(e.target)) {
          this.menus.forEach((m) => {
            if (m.itemId === e.target['id']) {
              m.isOpen = true;
            }
          });
        }
      });
      this.toggleMoreMenuButtons.forEach((toggleButton: ElementRef) => {
        if (toggleButton.nativeElement.contains(e.target)) {
          this.moreMenus.forEach((m) => {
            if (m.itemId === e.target['id']) {
              m.isOpen = true;
            }
          });
        }
      });
      this.toggleFullMenuButtons.forEach((toggleButton: ElementRef) => {
        if (toggleButton.nativeElement.contains(e.target)) {
          this.fullMenus.forEach((m) => {
            if (m.itemId === e.target['id']) {
              m.isOpen = true;
            }
          });
        }
      });
      if (
        !this.toggleMoreButton?.nativeElement?.contains(e.target) &&
        !this.moreMenu?.nativeElement?.contains(e.target)
      ) {
        this.moreMenuOpen = false;
      }
    });

    this.isLoggedIn = authService.isLoggedIn();
    this.authService.currentUser.subscribe((data) => {
      if (Object.keys(data).length !== 0) {
        this.userData = data;
        this.getAddresses();
        this.getCartDetail();
        this.getRecentOrders();
        setTimeout(() => {
          this.getRecentOrders();
        }, 60 * 1000);
      }
    });
    this.cartService.getProducts().subscribe((res) => {
      this.cartItemsCount = res.length;
    });
    this.genericService.getSearchString().subscribe((searchString) => {
      this.searchString = searchString;
    });
    this.authService.deliveryAddress.subscribe((data) => {
      if (data) {
        this.deliveryAddress = data;
      }
    });
    this.eddress.populateDeliveryAddress();
    this.genericService.cartCount.subscribe((quantity) => {
      this.cartItemsCount = quantity;
    });
    // this.languages = this.languages.filter((l) => l.isActive);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['menuCollections']?.currentValue.length > 0) {
      this.menuCollections = changes['menuCollections']?.currentValue;
      this.distributeMenuItems();
    }
    // this.menus.length = 0;
    // this.moreMenus.length = 0;
    // this.menuCollections.forEach((collection) => {
    //   this.fullMenus.push(collection);
    //   if (this.menus.length <= 7) {
    //     this.menus.push(collection);
    //   } else {
    //     this.moreMenus.push(collection);
    //   }
    // });
  }

  ngOnInit(): void {
    const rootStyles = getComputedStyle(document.documentElement);
    this.fillColor = rootStyles.getPropertyValue('--brand-700').trim();
    this.languages = this.languages?.filter((l) => l.isActive);
    this.isLoggedIn.subscribe((value) => {
      this.userLogedIn = value;
    });
    this.formCtrlSub = this.searchStringControl.valueChanges
      .pipe(debounceTime(800))
      .subscribe((newValue) => {
        if (newValue.length >= 3) {
          this.searchString = newValue;
          this.genericService.setSearchString(newValue);
          this.router.navigateByUrl('/search-products');
        } else {
          this.router.navigateByUrl('/');
        }
      });
    setTimeout(() => {
      const element = document.getElementById('tooltip-right');
      if (element) {
        element.classList.remove('opacity-0');
        element.classList.add('opacity-100');
      }

      const element2 = document.getElementById('frameContainer');
      if (element2) {
        element2.classList.remove('opacity-0');
        element2.classList.add('opacity-100');
      }
    }, 1000);
  }
  ngAfterViewInit() {
    this.distributeMenuItems();
    const dropdownButton = document.getElementById('dropdownButton');
    const dropdownMenu = document.getElementById('menuMobileDelay');

    dropdownButton.addEventListener('click', function (event) {
      event.stopPropagation();
      dropdownMenu.classList.toggle('hidden');
    });

    dropdownMenu.addEventListener('click', function (event) {
      event.stopPropagation();
    });

    document.addEventListener('click', function () {
      if (!dropdownMenu.classList.contains('hidden')) {
        dropdownMenu.classList.add('hidden');
      }
    });
    setTimeout(() => {
      const myDiv = document.getElementById('mega-menu');
      const rect = myDiv.getBoundingClientRect();
      const gap = window.innerWidth - rect.right;
      if (gap > 150) {
        this.moreMenuSide = 'right';
      } else {
        this.moreMenuSide = 'left';
      }
    }, 5000);
  }

  checkElementOverflowingHorizontally() {
    this.menus.length = 0;
    this.moreMenus.length = 0;
  }
  distributeMenuItems(): void {
    const menuContainerEl = this.menuContainer.nativeElement;
    const screenWidth = window.innerWidth;
    let totalWidth = 0;

    this.menus.length = 0;
    this.moreMenus.length = 0;
    this.fullMenus.length = 0;

    menuContainerEl.innerHTML = '';
    this.menuCollections = sortBy(this.menuCollections, (o) => o.sortOrder);
    this.menuCollections.forEach((item, index) => {
      item.groups = orderBy(item?.groups, ['label'], 'asc');
      item.groups = orderBy(item?.groups, ['sortOrder'], 'asc');
      const label = item.label ? item.label : item.collection?.label;
      const menuItemElement = document.createElement('div');
      menuItemElement.className = 'menu-item';
      menuItemElement.innerText = label;

      menuContainerEl.appendChild(menuItemElement);
      const itemWidth = menuItemElement.offsetWidth;
      totalWidth += itemWidth + 70;
      if (totalWidth <= screenWidth) {
        this.menus.push(item);
      } else {
        const menuExist = this.moreMenus.find((m) => item.itemId === m.itemId);
        if (!menuExist) {
          this.moreMenus.push(item);
          menuContainerEl.removeChild(menuItemElement);
        }
      }
      this.fullMenus.push(item);
    });
    setTimeout(() => {
      this.showMoreMenu = true;
    }, 1000);
    menuContainerEl.innerHTML = '';
  }

  onClickMenu(menu, group) {
    this.menus.forEach((m) => {
      m.isActive = false;
      m.isOpen = false;
      m.groups?.forEach((g) => (g.isActive = false));
    });
    this.moreMenus.forEach((m) => {
      m.isActive = false;
      m.isOpen = false;
      m.groups?.forEach((g) => (g.isActive = false));
    });
    this.fullMenus.forEach((m) => {
      m.isActive = false;
      m.isOpen = false;
      m.groups?.forEach((g) => (g.isActive = false));
    });
    menu.isActive = true;
    if (group?.label) {
      this.router.navigate([
        '/category',
        menu?.collection?.slug,
        group?.label ? `${group?.label.replace(/\s+/g, '-').toLowerCase()}` : null,
      ]);
    } else {
      this.router.navigate(['/category', menu?.collection?.slug]);
    }
  }
  toggleSubmenu(item: any): void {
    const label = item.label ? item.label : item.collection?.label;
    this.menus.forEach((m) => {
      m.isActive = false;
      if (m.label !== label) {
        m.isOpen = false;
      }
    });
    this.moreMenus.forEach((m) => {
      m.isActive = false;
      if (m.label !== label) {
        m.isOpen = false;
      }
    });
    this.fullMenus.forEach((m) => {
      m.isActive = false;
      if (m.label !== label) {
        m.isOpen = false;
      }
    });
    // item.isActive = true;
    item.isOpen = !item.isOpen;
  }

  openDrawer(type) {
    this.isDrawerOpen = true;

    this.isLoginOpen = false;
    this.isSignupOpen = false;
    this.isForgotPasswordOpen = false;
    this.isCartOpen = false;
    this.isLocationOpen = false;
    this.isAddressesOpen = false;

    if (type === 'login') {
      this.isLoginOpen = true;
    } else if (type === 'signup') {
      this.isSignupOpen = true;
    } else if (type === 'forgotPassword') {
      this.isForgotPasswordOpen = true;
    } else if (type === 'cart') {
      if (this.userLogedIn) {
        this.isCartOpen = true;
      } else {
        this.isLoginOpen = true;
      }
    } else if (type === 'location') {
      if (this.userLogedIn) {
        this.isLocationOpen = true;
      } else {
        this.isLoginOpen = true;
      }
    } else if (type === 'addresses') {
      if (this.userLogedIn) {
        this.isAddressesOpen = true;
      } else {
        this.isLoginOpen = true;
      }
    }
  }

  closeLogin($event) {
    this.isDrawerOpen = false;
    this.isLoginOpen = $event;
  }

  closeSignup($event) {
    this.isDrawerOpen = false;
    this.isSignupOpen = $event;
  }

  closeForgotPassword($event) {
    this.isDrawerOpen = false;
    this.isForgotPasswordOpen = $event;
  }

  closeCart($event) {
    this.isDrawerOpen = false;
    this.isCartOpen = $event;
  }
  closeLocation($event) {
    this.isDrawerOpen = false;
    this.isLocationOpen = $event;
  }
  closeAddresses($event) {
    this.isDrawerOpen = false;
    this.isLocationOpen = $event;
  }

  reset() {
    this.isDrawerOpen = false;
    this.isLoginOpen = false;
    this.isSignupOpen = false;
    this.isForgotPasswordOpen = false;
    this.isCartOpen = false;
    this.isDrawerOpen = false;
    this.isLocationOpen = false;
  }

  onLangChange(lang) {
    this.selectedLanguage = lang;
  }
  onCurrencyChange(currency) {
    this.selectedCurrency = currency;
  }
  onSearchBtnClick() {
    if (this.searchString.length > 0) {
      this.genericService.setSearchString(this.searchString);
      this.router.navigateByUrl('/search-products');
    }
  }
  onClearSearch() {
    if (this.searchString.length === 0) {
      this.genericService.setSearchString(this.searchString);
    }
  }
  getAddresses() {
    this.addressesService.getUserAddresses(this.userData.uid).subscribe((result) => {
      this.addressList = result;
      if (this.addressList.length === 1) {
        this.addressList[0].isDefault = true;
      }
      const defaultAddress = this.addressList.find((a) => a.isDefault);
      const localyStoredAddress = localStorage.getItem('deliveryAddress');
      if (defaultAddress && (!localyStoredAddress || localyStoredAddress === 'null')) {
        this.deliveryAddress = defaultAddress;
        this.authService.setUserDeliveryAddress(this.deliveryAddress);
      }
    });
  }
  getDeliveryAddress(deliveryAddress) {
    if (this.deliveryAddress !== deliveryAddress) {
      window.location.reload();
    }
    this.deliveryAddress = deliveryAddress;
    this.authService.setUserDeliveryAddress(this.deliveryAddress);
  }
  saveLocation(location) {
    const params = {
      address: {
        locality: location.locality,
        state: '',
        uid: this.userData.uid,
        version: '3.1.9',
        street: location.street,
        building: location.building,
        locale: 'es',
        os: 'web',
        type: '',
        appName: environment.eddressAppConfig.appName,
        coordinates: location.coordinates,
        city: '',
        phoneCountryCode: '0',
        idUser: this.userData.idUser,
        phoneCountryId: '0',
        notes: '',
        name: location.name,
        creationLat: location.coordinates.lat,
        creationLon: location.coordinates?.lon,
      },
    };
    this.loaderService.open();
    this.addressesService.saveUserAddress(params).subscribe(
      (result) => {
        this.loaderService.close();
        this.notification.successNotification('Address saved successfully');
        this.closeLocation(true);
        this.getAddresses();
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }
  getCartDetail() {
    this.genericService.getBasket().subscribe((result) => {
      this.cartItemsCount = result?.data?.quantity;
      this.genericService.setCartCount(this.cartItemsCount);
    });
  }
  getRecentOrders() {
    this.ordersService.getOrders(10).subscribe(
      (result) => {
        const activeOrders = this.getActiveOrders(result);
        if (activeOrders && activeOrders.length > 0) {
          const deiliveringOrder = activeOrders.find(
            (o) => o['dispatch.activeWorker.id'] && o.orderStatus !== 'OUT_FOR_DELIVERY'
          );
          if (deiliveringOrder) {
            if (deiliveringOrder?.etaLabel) {
              this.deliveringETA = deiliveringOrder?.etaLabel;
            } else {
              const deiliveringOrder2 = activeOrders.find((o) => o['dispatch.activeWorker.id']);
              this.deliverToETA = deiliveringOrder2?.etaLabel;
            }
          } else {
            this.deliverToETA = activeOrders[0].etaLabel;
          }
        }
      },
      (err) => {}
    );
  }
  getActiveOrders(orders): any[] {
    let activeOrders = [];
    activeOrders = orders.filter((order) => {
      if (
        order.orderStatus === 'NEW' ||
        order.orderStatus === 'VIEWED' ||
        order.orderStatus === 'PROCESSING' ||
        order.orderStatus === 'READY' ||
        order.orderStatus === 'CONFIRMED' ||
        order.orderStatus === 'DELIVERING' ||
        order.orderStatus === 'RETURNING' ||
        order.orderStatus === 'PICKING_UP' ||
        order.orderStatus === 'PICKED_UP' ||
        order.orderStatus === 'OUT_FOR_DELIVERY'
      ) {
        return order;
      }
    });
    return activeOrders;
  }
  resetHighlightedMenu() {
    this.menus.forEach((m) => (m.isActive = false));
    this.moreMenus.forEach((m) => (m.isActive = false));
    this.fullMenus.forEach((m) => (m.isActive = false));
  }
  resetModal() {
    this.isHeaderModalOpen = false;
  }
  closePopUp($event) {
    this.isHeaderModalOpen = false;
    this.isSignUpAlertOpen = $event;
  }
  openModal() {
    this.isHeaderModalOpen = true;
    setTimeout(() => {
      this.isSignUpAlertOpen = true;
      this.alertService.addAlert({
        title: 'Verify Your Email to Complete Signup',
        description:
          "Almost there! We've sent a verification link to your email. Please check your inbox and click the link to complete your signup.",
      });
    }, 1000);
  }
  logout() {
    this.authService.removeUserAuthentication();
    this.authService.removeUserDeliveryAddress();
    this.authService.removeUserSelectedStore();
    localStorage.clear();
    this.router.navigateByUrl('/');
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }
}
