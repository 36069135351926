.ngxImageZoomContainer {
    position: relative;
    margin: auto;
    overflow: hidden;
    pointer-events: none;
}

.ngxImageZoomThumbnail {
    pointer-events: all;
}

.ngxImageZoomFull {
    position: absolute;
    max-width: none;
    max-height: none;
    display: none;
    pointer-events: none;
}

.ngxImageZoomFullContainer {
    position: absolute;
    overflow: hidden;
    pointer-events: none;
}

.ngxImageZoomFullContainer.ngxImageZoomLensEnabled {
    border: 2px solid red;
    cursor: crosshair;
    pointer-events: none;
}

.remove-text {
    font-size: 15px;
}