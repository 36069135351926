import { Component, OnInit } from '@angular/core';
import { MyWalletService } from './services/my-wallet.service';
import { LoaderService } from '@app/core/services/loader.service';
import { GenericService } from '@app/core/services/generics.service';
import { NgIf, NgFor, DecimalPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-my-wallet',
    templateUrl: './my-wallet.component.html',
    styleUrl: './my-wallet.component.scss',
    imports: [NgIf, NgFor, DecimalPipe, DatePipe]
})
export class MyWalletComponent implements OnInit {
  amountInWallet = 0;
  cashBackEarned = 0;
  userWalletHistory = [];
  configuration;
  constructor(
    private myWalletService: MyWalletService,
    private loaderService: LoaderService,
    private genericService: GenericService
  ) {
    this.genericService.getConfigurations().subscribe((result) => {
      this.configuration = result;
    });
  }
  ngOnInit() {
    // this.genericService.setPageTitleAndDescription('my-wallet', '')
    this.getWalletHistory();
  }
  getWalletHistory() {
    this.loaderService.open();
    this.myWalletService.getWalletHistory().subscribe(
      (result) => {
        this.loaderService.close();
        this.amountInWallet = result.data?.amountInWallet;
        this.cashBackEarned = result.data?.cashBackEarned;
        this.userWalletHistory = result?.data?.userWalletHistory;
      },
      (error) => {
        this.loaderService.close();
      }
    );
  }
}
