import { Router } from '@angular/router';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GenericService } from '@app/core/services/generics.service';
import { AuthService } from '@app/core/services/auth.service';
import { NotificationService } from '@app/core/services/notification.service';
import { NgIf, NgFor, DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-cart',
    templateUrl: './cart.component.html',
    styleUrl: './cart.component.scss',
    imports: [NgIf, NgFor, DecimalPipe]
})
export class CartComponent implements OnInit {
  @Output() closeDrawer = new EventEmitter<boolean>();
  public products: any = [];
  public grandTotal!: number;
  selectedStore;
  configuration;
  cartItemCount;
  deliveryAddress;
  constructor(
    private router: Router,
    private genericService: GenericService,
    private authService: AuthService,
    private notificationService: NotificationService
  ) {
    this.authService.selectedStore.subscribe((data) => {
      if (data) {
        this.selectedStore = data;
      }
    });
    this.genericService.getConfigurations().subscribe((result) => {
      this.configuration = result;
    });
    this.authService.deliveryAddress.subscribe((data) => {
      if (data) {
        this.deliveryAddress = data;
      }
    });
  }

  ngOnInit(): void {
    // this.cartService.getProducts().subscribe((res) => {
    //   this.products = res;
    //   this.grandTotal = this.cartService.getTotalPrice();
    // });
    this.getCartDetail();
  }

  removeQuantity(item) {
    item.quantity -= 1;
    this.manageBasket(item);
    this.genericService.updateCartItem(item);
    // this.cartService.removeCartItem(item);
  }

  addQuantity(item) {
    item.quantity += 1;
    this.manageBasket(item);
    this.genericService.updateCartItem(item);
    // this.cartService.addtoCart(item);
  }
  removeItem(item) {
    item.quantity = -1;
    this.manageBasket(item);
    this.genericService.updateCartItem(item);
  }
  close() {
    this.closeDrawer.emit(false);
  }
  manageBasket(item) {
    const params = {
      storeId: this.selectedStore.id,
      productId: item.productId,
      quantity: item.quantity,
    };
    this.genericService.manageBasket(params).subscribe((result) => {
      item.product.basketTotalQuantity = item.quantity;
      const product = {
        basketTotalQuantity: item.quantity,
        favorite: item.product.isFavorite,
        isFavorite: item.product.isFavorite,
        itemId: item.productId,
        productItem: item.product,
        sortOrder: item.product.sortOrder,
        type: item.product.itemType,
      };
      this.genericService.updateHomepageProducts(product, 'cart');
      this.getCartDetail();
    });
  }
  clearAllBasket() {
    this.genericService.clearBasket().subscribe((result) => {
      this.products.forEach((item) => {
        item.product.basketTotalQuantity = 0;
        const product = {
          basketTotalQuantity: 0,
          favorite: item.product.isFavorite,
          isFavorite: item.product.isFavorite,
          itemId: item.productId,
          productItem: item.product,
          sortOrder: item.product.sortOrder,
          type: item.product.itemType,
        };
        this.genericService.updateHomepageProducts(product, 'cart');
      });
      this.getCartDetail();
    });
  }
  getCartDetail() {
    this.genericService.getBasket().subscribe((result) => {
      this.products = result?.data?.items;
      this.cartItemCount = result?.data?.quantity;
      this.grandTotal = result?.data?.totalPrice;
      if (this.router.url === '/checkout' && this.grandTotal < this.selectedStore.minimumCharge) {
        this.router.navigate(['/view-cart']);
      }
    });
  }

  viewCart() {
    if (!this.deliveryAddress) {
      this.notificationService.errorNotification('Please Enter Address!');
      return;
    }
    this.close();
    this.router.navigate(['/view-cart']);
  }
  checkout() {
    if (this.grandTotal < this.selectedStore.minimumCharge) {
      this.notificationService.infoNotification(
        `Minimum charge not reached. You need to exceed minimum charge to proceed.`
      );
      return;
    }
    if (!this.deliveryAddress) {
      this.notificationService.errorNotification('Please Enter Address!');
      return;
    }
    this.close();
    this.router.navigate(['/checkout']);
  }
}
