import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { map, distinctUntilChanged } from 'rxjs/operators';
import { LocalStorageService } from './localstorage.service';
import { User } from '../models/user.model';
import { FacebookAuthProvider, GoogleAuthProvider } from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '@environments/environment';
import { LOGIN_EXPIRY } from '@app/shared/variables/loginVariables';
import { NotificationService } from './notification.service';
import { FirebaseAuthErrorHandler } from '../error/firebaseError';
import { LoaderService } from './loader.service';
import { Address } from '../models/address.model';
import { Store } from '../models/store.model';
import { Urls } from '../util/urls';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private currentUserSubject = new BehaviorSubject<User>({} as User);
  public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());

  private deliveryAddressSubject = new BehaviorSubject<Address>(null as Address);
  public deliveryAddress = this.deliveryAddressSubject.asObservable().pipe(distinctUntilChanged());

  private selectedStoreSubject = new BehaviorSubject<Store>(null as Store);
  public selectedStore = this.selectedStoreSubject.asObservable().pipe(distinctUntilChanged());

  private homePageDataSubject = new BehaviorSubject<any>(null as any);
  public homePageData = this.homePageDataSubject.asObservable().pipe(distinctUntilChanged());

  public isAuthenticatedSubject = new BehaviorSubject<boolean>(false);
  public isAuthenticated = this.isAuthenticatedSubject.asObservable();

  constructor(
    private api: ApiService,
    private localStorage: LocalStorageService,
    public afAuth: AngularFireAuth,
    private notification: NotificationService,
    private loaderService: LoaderService
  ) {}

  FacebookAuth() {
    return this.AuthLogin(new FacebookAuthProvider());
  }

  GoogleAuth() {
    return this.AuthLogin(new GoogleAuthProvider());
  }

  AuthLogin(provider: any) {
    this.loaderService.open();
    return this.afAuth
      .signInWithPopup(provider)
      .then((result) => {
        const userProfile: any = result?.additionalUserInfo?.profile;

        const user: any = result?.user;
        const uid = user?._delegate?.uid;

        const credentials = {
          appName: environment.eddressAppConfig.appName, // from frontend
          authUid: uid,
          email: userProfile.email,
          fullName: userProfile.name,
          appIdentifier: '', // not needed on web
          os: 'WEB',
          locale: 'es', // from frontend localized
          // authProvider: result.user.password,
        };
        this.backendAuthenticate(credentials).subscribe((data) => {
          this.setToken(data.jwtToken);
          this.setUserAuthentication(data);
          this.loaderService.close();
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        });
      })
      .catch((error) => {
        this.loaderService.close();
        const errorMessage = FirebaseAuthErrorHandler.convertMessage(error['code']);
        this.notification.errorNotification(errorMessage);
      });
  }

  attemptAuthByEmailAndPassword(email, password): Observable<any> {
    return from(
      this.afAuth
        .signInWithEmailAndPassword(email, password)
        .then((result) => {
          return result;
        })
        .catch((error) => {
          console.log(error);
          throw error;
        })
    );
  }
  attemptAuthByPhoneNumber(phoneNumber, appVerifier): Observable<any> {
    return from(
      this.afAuth
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          return confirmationResult;
        })
        .catch((error) => {
          throw error;
        })
    );
  }

  createWhatsAppOtp(params) {
    return this.api.post(Urls.CREATE_OTP, params).pipe(
      map((data) => {
        return data;
      })
    );
  }
  verifyWhatsAppOtp(params) {
    return this.api.post(Urls.VERIFY_OTP, params).pipe(
      map((data) => {
        return data;
      })
    );
  }

  signUp(email, password) {
    return this.afAuth.createUserWithEmailAndPassword(email, password);
  }

  forgotPassword(email) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  backendAuthenticate(credentials) {
    return this.api.post(Urls.AUTHENTICATE_PUBLIC, credentials);
  }

  setLoginWithExpiryForLocalStorage(token) {
    const expiryDate = Date.now() + LOGIN_EXPIRY;

    const login = {
      jwt: token,
      expiry: expiryDate,
    };

    this.localStorage.setItem('firebaseToken', login);
  }

  setToken(token: String) {
    this.localStorage.setItem('token', token);
  }

  getBearerToken() {
    const token = this.localStorage.getItem('token');
    if (token) {
      return token;
    }
  }

  setUserObject(user: User) {
    this.localStorage.setItem('user', user);
  }

  getUserObject(): User {
    return this.localStorage.getItem('user');
  }

  populateUser() {
    const token = this.getBearerToken();
    if (token) {
      this.setToken(token);
      this.setUserAuthentication(this.getUserObject());
    } else {
      this.removeUserAuthentication();
      // this.router.navigateByUrl('/portal/login');
    }
  }

  setUserAuthentication(
    user: User
    // storeId: string = null,
    // config: string = null
  ) {
    // if (storeId) user.storeId = storeId;
    // if (config) user.config = config;
    this.currentUserSubject.next(user);
    this.isAuthenticatedSubject.next(true);
    // this.isLoginAuthenticatedSubject.next(false);
    this.setUserObject(user);
  }
  removeUserAuthentication() {
    this.localStorage.clearItems();
    this.currentUserSubject.next({} as User);
    this.isAuthenticatedSubject.next(false);
    // this.isLoginAuthenticatedSubject.next(true);
  }
  isLoggedIn(): Observable<boolean> {
    return this.isAuthenticated;
  }
  populateDeliveryAddress() {
    const token = this.getBearerToken();
    if (token) {
      this.setUserDeliveryAddress(this.getDeliveryAddressObject());
    } else {
      this.removeUserDeliveryAddress();
    }
  }
  setDeliveryAddressObject(address: Address) {
    this.localStorage.setItem('deliveryAddress', address);
  }

  getDeliveryAddressObject(): Address {
    return this.localStorage.getItem('deliveryAddress');
  }
  setUserDeliveryAddress(address: Address) {
    this.deliveryAddressSubject.next(address);
    this.setDeliveryAddressObject(address);
  }
  removeUserDeliveryAddress() {
    this.deliveryAddressSubject.next(null as Address);
  }
  // for Selected Store
  populateSelectedStore() {
    const token = this.getBearerToken();
    if (token) {
      this.setUserSelectedStore(this.getSelectedStoreObject());
    } else {
      this.removeUserSelectedStore();
    }
  }
  populateHomePageData() {
    const token = this.getBearerToken();
    if (token) {
      this.setHomePageData(this.getHomePageObject());
    } else {
      this.removesetHomePageData();
    }
  }
  setSelectedStoreObject(store: Store) {
    this.localStorage.setItem('selectedStore', store);
  }
  setHomePageDataObject(homePage) {
    this.localStorage.setItem('homePageData', homePage);
  }

  getSelectedStoreObject(): Store {
    return this.localStorage.getItem('selectedStore');
  }
  getHomePageObject(): Store {
    return this.localStorage.getItem('homePageData');
  }
  setUserSelectedStore(store: Store) {
    this.selectedStoreSubject.next(store);
    this.setSelectedStoreObject(store);
  }
  removeUserSelectedStore() {
    this.selectedStoreSubject.next(null as Store);
  }
  setHomePageData(homePage) {
    this.homePageDataSubject.next(homePage);
    this.setHomePageDataObject(homePage);
  }
  removesetHomePageData() {
    this.homePageDataSubject.next(null);
  }
}
