import { Component, Input, Output, EventEmitter } from '@angular/core';
import { GenericService } from '@app/core/services/generics.service';
import { NgIf, DecimalPipe } from '@angular/common';

@Component({
    selector: 'app-view-cart-item',
    templateUrl: './view-cart-item.component.html',
    styleUrl: './view-cart-item.component.scss',
    imports: [NgIf, DecimalPipe]
})
export class ViewCartItemComponent {
  @Input() item: any;

  @Output() increaseQuantity: EventEmitter<any> = new EventEmitter();
  @Output() decreaseQuantity: EventEmitter<any> = new EventEmitter();
  @Output() itemToRemove: EventEmitter<any> = new EventEmitter();
  configuration;
  constructor(private genericService: GenericService) {
    this.genericService.getConfigurations().subscribe((result) => {
      this.configuration = result;
    });
  }

  incrementQuantity() {
    this.increaseQuantity.emit(this.item);
  }

  decrementQuantity() {
    this.decreaseQuantity.emit(this.item);
  }

  removeItem() {
    this.itemToRemove.emit(this.item);
  }
}
