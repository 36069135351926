import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from '@app/core/services/generics.service';
import { NgFor } from '@angular/common';

@Component({
    selector: 'app-missing-items',
    templateUrl: './missing-items.component.html',
    styleUrl: './missing-items.component.scss',
    imports: [NgFor]
})
export class MissingItemsComponent {
  @Input() items = [];
  @Output() closeDrawer = new EventEmitter<boolean>();
  configuration;
  constructor(private genericService: GenericService, public router: Router) {
    this.genericService.getConfigurations().subscribe((result) => {
      this.configuration = result;
    });
  }
  close() {
    this.closeDrawer.emit(false);
  }
  continueShopping() {
    this.genericService.hideBackdrop()
    this.router.navigate(['/']);
  }
}
