import { MetaService } from './../shared/services/meta.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ProductService } from './services/product.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GenericService } from '@app/core/services/generics.service';
import { LoaderService } from '@app/core/services/loader.service';
import { AuthService } from '@app/core/services/auth.service';
import { AddressesService } from '@app/user-information/addresses/services/addresses.service';
import { NotificationService } from '@app/core/services/notification.service';
import { environment } from '@environments/environment';
import { NgIf, NgFor, NgClass, DecimalPipe } from '@angular/common';
import { BreadcrumbService } from '@app/shared/services/breadcrumb.service';
import { ForgotPasswordComponent } from '../layout/header/components/forgot-password/forgot-password.component';
import { SignupComponent } from '../layout/header/components/signup/signup.component';
import { LoginComponent } from '../layout/header/components/login/login.component';
import { AddressListComponent } from '../shared/components/address-list/address-list.component';
import { AddLocationComponent } from '../shared/components/add-location/add-location.component';
import { DrawerComponent } from '../shared/drawer/drawer.component';
import { Title } from '@angular/platform-browser';
import { LandingBestSellersComponent } from '@app/landing/components/landing-best-sellers/landing-best-sellers.component';
import { filter, switchMap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';

@Component({
    selector: 'app-product',
    templateUrl: './product.component.html',
    styleUrl: './product.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [
        NgIf,
        NgFor,
        NgClass,
        DrawerComponent,
        AddLocationComponent,
        AddressListComponent,
        LoginComponent,
        SignupComponent,
        ForgotPasswordComponent,
        DecimalPipe,
        LandingBestSellersComponent,
    ]
})
export class ProductComponent implements OnInit {
  breadcrumbs: Array<{ label: string; url: string }> = [];
  similarProductsSubscription: Subscription;
  images: any[] = [];
  product: any;
  user = null;
  selectedImage = {
    imageUrl: '',
    thumbnailUrl: '',
    index: null,
  };

  configuration;
  selectedStore;
  productId;
  collectionId;
  slug;

  isFavorite = false;
  isShareDropdownOpen = false;
  isDescriptionOpen = false;
  isAdditivesOpen = false;
  quantity = 1;

  currentUrl = '';

  isDrawerOpen: boolean = false;
  isLocationOpen: boolean = false;
  isAddressesOpen: boolean = false;
  isLoginDrawerOpen: boolean = false;
  isSignupDrawerOpen: boolean = false;
  isForgotPasswordDrawerOpen: boolean = false;
  deliveryAddress;
  homePageData;
  similarProducts = [];

  constructor(
    private productService: ProductService,
    private route: ActivatedRoute,
    private genericService: GenericService,
    private loaderService: LoaderService,
    private authService: AuthService,
    private addressesService: AddressesService,
    private notification: NotificationService,
    private breadcrumbService: BreadcrumbService,
    private metaService: MetaService,
    public router: Router,
    private titleService: Title
  ) {
    this.route.params.subscribe((param) => {
      this.slug = param['slug'];
    });
    this.authService.currentUser.subscribe((data) => {
      if (Object.keys(data).length !== 0) {
        this.user = data;
      }
    });
    this.authService.homePageData.subscribe((data) => {
      if (data) {
        this.homePageData = data;
      }
    });
    this.genericService.updateProductItem.subscribe((data) => {
      if (data) {
        if (this.product?.itemId === data?.itemId) {
          this.quantity = data.basketTotalQuantity;
          if (this.quantity === 0) {
            this.quantity = 1;
          }
        }
      }
    });
    this.authService.selectedStore.subscribe((data) => {
      if (data) {
        this.selectedStore = data;
        if (this.route.snapshot.data['productDetail']) {
          this.setProductDetail(this.route.snapshot.data['productDetail']);
        } else {
          this.getProductDetail();
        }
      }
    });

    // this.product = this.route.snapshot.data['productDetail'];

    this.genericService.getConfigurations().subscribe((result) => {
      this.configuration = result;
    });
    this.authService.deliveryAddress.subscribe((data) => {
      if (data) {
        this.deliveryAddress = data;
      }
    });
  }

  ngOnInit() {
    this.scrollTop();
    // this.genericService.setPageTitleAndDescription('product', '')
    this.breadcrumbs = this.breadcrumbService.breadcrumbs;
    document.addEventListener('click', () => (this.isShareDropdownOpen = false));
    this.genericService.checkCanonicalTag();
    this.similarProductsSubscription = this.productService.productUrlChange$
      .pipe(
        filter((newSlug) => !!newSlug), // Ignore null or undefined
        switchMap((newSlug) => {
          this.loaderService.open();
          if (this.selectedStore) {
            const params = {
              storeId: this.selectedStore.id,
              slug: newSlug,
            };
            return this.productService.getProductDetail(params);
          } else {
            return of(null);
          }
        })
      )
      .subscribe((productDetails) => {
        this.loaderService.close();
        if (productDetails) this.setProductDetail(productDetails);
      });
  }

  ngOnDestroy() {
    this.similarProductsSubscription.unsubscribe();
    document.removeEventListener('click', () => (this.isShareDropdownOpen = false));
    this.genericService.setPageMetaKeywords('');
    this.genericService.setPageMetaDescription('');
  }

  addToCart() {
    if (!this.user && this.user === null) {
      this.openDrawer('login');
    } else if (this.deliveryAddress) {
      const params = {
        storeId: this.selectedStore.id,
        productId: this.product.id,
        quantity: this.quantity,
      };
      this.loaderService.open();
      this.genericService.manageBasket(params).subscribe((result) => {
        this.loaderService.close();
        this.product.basketTotalQuantity = this.quantity;
        this.product.itemId = this.product.id;
        this.genericService.updateHomepageProducts(this.product, '');
      });
    } else {
      this.openDrawer('addresses');
    }
  }

  selectImage(image, index) {
    this.selectedImage = {
      imageUrl: image.imageWebUrl ? image.imageWebUrl : image.imageUrl,
      thumbnailUrl: image.thumbnailUrl,
      index: index,
    };
  }

  showDescription() {
    this.isDescriptionOpen = !this.isDescriptionOpen;
    this.isAdditivesOpen = false;
  }

  showAdditives() {
    this.isAdditivesOpen = !this.isAdditivesOpen;
    this.isDescriptionOpen = false;
  }
  getProductDetail() {
    if (this.selectedStore) {
      const params = {
        storeId: this.selectedStore.id,
        slug: this.slug,
      };
      this.loaderService.open();
      this.productService.getProductDetail(params).subscribe(
        (result) => {
          this.setProductDetail(result);
        },
        (err) => {
          this.loaderService.close();
        }
      );
    }
  }
  setProductDetail(result) {
    this.product = result?.product;
    this.similarProducts = result?.similarProducts.map((product) => ({
      ...product,
      isSimilar: true,
    }));
    this.loaderService.close();
    if (this.collectionId) {
      this.product.productCollections = this.product.productCollections.filter(
        (c) => c.id === this.collectionId
      );
    }
    if (this.product.webMetaKeywords) {
      this.metaService.setMetaTag(this.product.webMetaKeywords);
    }
    this.titleService.setTitle(
      this.product?.label + ' | ' + environment.eddressAppConfig.companyTitle
    );
    if (this.product?.websiteTitle) {
      this.titleService.setTitle(this.product?.websiteTitle);
    } else {
      // this.genericService.setPageTitleAndDescription('product', this.product?.label);
    }
    setTimeout(() => {
      if (this.product?.websiteMetaDescription) {
        this.genericService.setPageMetaDescription(this.product?.websiteMetaDescription);
      }
    }, 1000);

    this.genericService.setPageMetaKeywords(this.product?.websiteMetaKeywords);
    this.isFavorite = this.product.isFavorite;
    this.quantity = this.product?.basketTotalQuantity || 1;
    const imageObject = {
      imageUrl: this.product.imageUrl,
      thumbnailUrl: this.product.thumbnailUrl,
    };
    this.images.length = 0;
    this.images.push(imageObject);

    if (this.images.length > 0) {
      this.selectedImage = this.images[0].imageUrl;

      this.selectedImage = {
        imageUrl: this.images[0].imageWebUrl ? this.images[0].imageWebUrl : this.images[0].imageUrl,
        thumbnailUrl: this.images[0].thumbnailUrl,
        index: 0,
      };
    }

    if (this.product?.additionalImages?.length > 0) {
      this.product.additionalImages.forEach((img) => {
        const additionalImageObj = {
          imageUrl: img.normal,
          thumbnailUrl: img.thumbnailUrl,
        };
        this.images.push(additionalImageObj);
      });
    }
  }

  addQuantity() {
    this.quantity += 1;
  }

  removeQuantity() {
    if (this.quantity > 0) {
      this.quantity -= 1;
    }
  }

  addToFavourite() {
    if (!this.user && this.user === null) {
      this.openDrawer('login');
    } else {
      this.isFavorite = !this.isFavorite;
      const params = {
        addItem: this.isFavorite,
        productId: this.product.id,
      };
      this.loaderService.open();
      this.productService.addToFavorites(params).subscribe((result) => {
        this.loaderService.close();
        this.product.itemId = this.product.id;
        this.product.isFavorite = this.isFavorite;
        this.genericService.updateHomepageProducts(this.product, '');
      });
    }
  }
  openDrawer(type) {
    this.isDrawerOpen = true;

    this.isLocationOpen = false;
    this.isAddressesOpen = false;
    this.isLoginDrawerOpen = false;
    this.isSignupDrawerOpen = false;
    this.isForgotPasswordDrawerOpen = false;
    if (type === 'location') {
      this.isLocationOpen = true;
    } else if (type === 'addresses') {
      this.isAddressesOpen = true;
    } else if (type === 'login') {
      this.isLoginDrawerOpen = true;
    } else if (type === 'signup') {
      this.isSignupDrawerOpen = true;
    } else if (type === 'forgotPassword') {
      this.isForgotPasswordDrawerOpen = true;
    }
  }
  closeLocation($event) {
    this.isDrawerOpen = false;
    this.isLocationOpen = $event;
  }
  closeAddresses($event) {
    this.isDrawerOpen = false;
    this.isLocationOpen = $event;
  }

  closeLogin($event) {
    this.isDrawerOpen = false;
    this.isLoginDrawerOpen = $event;
  }

  closeSignup($event) {
    this.isDrawerOpen = false;
    this.isSignupDrawerOpen = $event;
  }

  closeForgotPassword($event) {
    this.isDrawerOpen = false;
    this.isForgotPasswordDrawerOpen = $event;
  }

  reset() {
    this.isDrawerOpen = false;
    this.isDrawerOpen = false;
    this.isLocationOpen = false;
  }
  getDeliveryAddress(deliveryAddress) {
    if (this.deliveryAddress !== deliveryAddress) {
      window.location.reload();
    }
    this.deliveryAddress = deliveryAddress;
    this.authService.setUserDeliveryAddress(this.deliveryAddress);
  }
  saveLocation(location) {
    const params = {
      address: {
        locality: location.locality,
        state: '',
        uid: this.user.uid,
        version: '3.1.9',
        street: location.street,
        building: location.building,
        locale: 'es',
        os: 'web',
        type: '',
        appName: environment.eddressAppConfig.appName,
        coordinates: location.coordinates,
        city: '',
        phoneCountryCode: '0',
        idUser: this.user.idUser,
        phoneCountryId: '0',
        notes: '',
        name: location.name,
        creationLat: location.coordinates.lat,
        creationLon: location.coordinates?.lon,
      },
    };
    this.loaderService.open();
    this.addressesService.saveUserAddress(params).subscribe(
      (result) => {
        this.loaderService.close();
        this.notification.successNotification('Address saved successfully');
        this.closeLocation(true);
      },
      (err) => {
        this.loaderService.close();
      }
    );
  }

  toggleShareDropdown() {
    this.isShareDropdownOpen = !this.isShareDropdownOpen;
    event.stopPropagation();
  }

  selectShareOption(option: string) {
    this.currentUrl = window.location.href;
  }
  viewCollection(collection, group) {
    if (group) {
      this.router.navigate([
        '/category',
        collection?.collectionSlug,
        group ? `${group.replace(/\s+/g, '-').toLowerCase()}` : null,
      ]);
    } else {
      this.router.navigate(['/category', collection?.collectionSlug]);
    }
  }
  scrollTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }
}
