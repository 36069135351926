import { Injectable } from '@angular/core';
import { ApiService } from '@app/core/services/api.service';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor(private api: ApiService) {}
  getProductsByCollections(params, queryParams): Observable<any> {
    return this.api
      .get('api/market/app/store/' + params.storeId + '/collections/' + params.slug, queryParams)
      .pipe(map((data) => data));
  }
}
