import { AfterViewInit, Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { User } from '@app/core/models/user.model';
import { AuthService } from '@app/core/services/auth.service';
import { environment } from '@environments/environment';
import { SafePipe } from '../../../../shared/pipes/safe.pipe';

@Component({
    selector: 'app-add-cardknox-card',
    templateUrl: './add-cardknox-card.component.html',
    styleUrl: './add-cardknox-card.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [SafePipe]
})
export class AddCardknoxCardComponent implements OnInit, AfterViewInit, OnDestroy  {
  @Output() closeDrawer = new EventEmitter<boolean>();
  @Output() onSubmit = new EventEmitter<any>();
  user: User;
  iframe: HTMLIFrameElement | null = null;
  eventListener: any;
  pageSrc;
  allowedOrigins = ['http://localhost:4200', 'https://pronto-83d5a.web.app', 'https://pronto.bm'];
  constructor(private authService: AuthService) {
    this.authService.currentUser.subscribe((data) => {
      this.user = data;
      this.pageSrc =
        environment.eddressAppConfig.apiBaseUrl + 'v1/cardknox/paymentInfo/' + this.user.uid+ '?os=web' ;
    });
  }
  ngOnInit(): void {}
  ngAfterViewInit(): void {
    this.iframe = document.getElementById('embeddedFrame') as HTMLIFrameElement;
    this.eventListener = this.receiveMessage.bind(this);
    window.addEventListener('message', this.receiveMessage.bind(this), false);
  }
  close() {
    this.closeDrawer.emit(false);
  }
  onSubmitFn() {
   
  }
  receiveMessage(event: MessageEvent): void {
    if (!this.allowedOrigins.includes(event.origin)) {
      this.onSubmit.emit(event.data);
      this.close();
      // Check the origin of the message for security reasons
      return;
    }
  }
  ngOnDestroy(): void {
    if (this.eventListener) {
      window.removeEventListener('message', this.eventListener, false);
    }
  }
}
