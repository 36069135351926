import {
  Component,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  Input,
  AfterViewInit,
  OnInit,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';
import { ProductItemComponent } from '../../../shared/product-item/product-item.component';
import { NgClass, NgIf, NgFor } from '@angular/common';
import sortBy from 'lodash/sortBy';

@Component({
    selector: 'app-landing-best-sellers',
    templateUrl: './landing-best-sellers.component.html',
    styleUrl: './landing-best-sellers.component.scss',
    encapsulation: ViewEncapsulation.None,
    imports: [
        NgClass,
        NgIf,
        NgFor,
        ProductItemComponent,
    ]
})
export class LandingBestSellersComponent implements OnInit, AfterViewInit {
  @Input() title: string;
  @Input() items: any[] = [];
  @Output() openAddressDrawer: EventEmitter<any> = new EventEmitter<any>();
  @Output() openLoginDrawer: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('frameContainerBestSellers') frameContainerBestSellers: ElementRef;
  @ViewChild('nextButton') nextButton: ElementRef;
  @ViewChild('previousButton') previousButton: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.checkElementOverflowingHorizontally();
  }
  showArrowButtons = true;
  initialScrollPosition: number;
  buttonInitialRight: number;
  buttonInitialLeft: number;

  constructor() {
    this.onResize();
  }

  ngOnInit() {
    this.items = sortBy(this.items, (o) => o.sortOrder);
    this.items?.forEach((item) => {
      if(item.productItem) {
        item.productItem.basketTotalQuantity = item?.basketTotalQuantity;
        item.productItem.isFavorite = item?.isFavorite;
      }
    });
  }

  ngAfterViewInit() {
    this.initialScrollPosition = this.frameContainerBestSellers?.nativeElement?.scrollLeft;
    this.buttonInitialRight =
      window.innerWidth - this.nextButton?.nativeElement?.getBoundingClientRect()?.right;
    this.buttonInitialLeft = this.previousButton?.nativeElement?.getBoundingClientRect()?.left;
    setTimeout(() => {
      this.checkElementOverflowingHorizontally();
    }, 0);
  }

  checkElementOverflowingHorizontally() {
    const scrollWidth = this.frameContainerBestSellers?.nativeElement?.scrollWidth;
    const clientWidth = this.frameContainerBestSellers?.nativeElement?.clientWidth;
    if (scrollWidth > clientWidth) {
      this.showArrowButtons = true;
    } else {
      this.showArrowButtons = false;
    }
  }

  scrollNext() {
    const container = this.frameContainerBestSellers.nativeElement;
    const scrollAmount = 500;
    const targetScroll = container.scrollLeft + scrollAmount;

    container.scrollTo({
      left: targetScroll,
      behavior: 'smooth',
    });

    this.nextButton.nativeElement.style.right = this.buttonInitialRight + 'px';
    this.previousButton.nativeElement.style.left = this.buttonInitialLeft + 'px';
  }

  scrollPrevious() {
    const container = this.frameContainerBestSellers.nativeElement;
    const scrollAmount = 500;
    const targetScroll = container.scrollLeft - scrollAmount;

    container.scrollTo({
      left: targetScroll,
      behavior: 'smooth',
    });

    this.nextButton.nativeElement.style.right = this.buttonInitialRight + 'px';
    this.previousButton.nativeElement.style.left = this.buttonInitialLeft + 'px';
  }

  toggleExpansion(item: any) {
    item.isExpanded = true;
  }
  openAddressDrawerFn() {
    this.openAddressDrawer.emit();
  }

  openLoginDrawerFn() {
    this.openLoginDrawer.emit();
  }
}
